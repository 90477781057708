import { SwitchWrapper } from './status.style'
import { FormSection } from 'components/form-section/form-section'
import { Typography } from 'antd'
import { useTranslate } from 'utils'
import { VerticalContainer } from 'components/styled'
import { FormSwitch } from 'components/form-inputs'
import { Field, useField } from 'react-final-form'
const { Text, Paragraph } = Typography

type Props = {
    is: string,
    enabledDescription: string
    disabledDescription: string
}

export const Status = ({ is, enabledDescription, disabledDescription }: Props) => {

    const { __ } = useTranslate()
    const { input: { value: status } } = useField('status')

    return (
        <FormSection>

            <VerticalContainer>

                <SwitchWrapper>

                    {/* title */}
                    <Text>

                        {is}
                        &nbsp;
                        <Text style={{ fontWeight: 'bold' }}>
                            {status ? __`enabled` : __`disabled`}
                        </Text>

                    </Text>

                    {/* switch */}
                    <Field
                        name="status"
                        type="switch"
                        component={FormSwitch}
                    />

                </SwitchWrapper>

                <Paragraph type='secondary' style={{ marginBottom: 0 }}>
                    {status ? enabledDescription : disabledDescription}
                </Paragraph>

            </VerticalContainer>

        </FormSection>
    )

}
