import { CACHE_REFETCH_INTERVAL, CALENDAR_SLOTS_CACHE_STALE_TIME } from 'app.constants'
import { CalendarSlot, CalendarSlotApiResponse } from 'types'
import { getIsoStringYearMonthDay, useMutationHandler, useQueryHandler, getShopId, getLocationId } from 'utils'

//slots:
export const useCalendarSlots = (
    resourceIds: string[],
    date: Date,
    enabled: boolean
) => {

    const shopId = getShopId()
    const locationId = getLocationId()
    const isoStringYearMonthDay = getIsoStringYearMonthDay(date)

    return useQueryHandler<CalendarSlotApiResponse>({
        query: `
            query getCalendarBlockSlots (
                $request: GetCalendarBlockSlotsRequest!
            ) {
                getCalendarBlockSlots (
                    request: $request
                ) {

                    date
                    now

                    resources {

                        id
                        
                        slots {

                            dateTime
                            duration
                            variantTitle

                            service {
                                id
                                title
                                groupAppointments
                                groupAppointmentSlots
                            }

                            items {

                                id
                                type

                                customer {
                                    id
                                    name
                                    email
                                    image {
                                        id
                                        path
                                    }
                                    phone
                                    billingPhone
                                }

                            }

                        }

                    }

                }
            }
        `,
        variables: {
            request: {
                shop: shopId,
                locationId,
                date: isoStringYearMonthDay,
                resourceIds
            }
        },
        cacheKey: ['calendarSlots', isoStringYearMonthDay, resourceIds, shopId, locationId],
        onSuccess: json => {
            const response: CalendarSlotApiResponse = json.data.getCalendarBlockSlots
            return {
                ...response,
                resources: response.resources.map(resource => {
                    return {
                        ...resource,
                        slots: sortSlots(resource.slots)
                    }
                })
            }
        },
        enabled,
        staleTime: CALENDAR_SLOTS_CACHE_STALE_TIME,
        refetchInterval: CACHE_REFETCH_INTERVAL,
        keepPreviousData: true
    })

}

//sortSlots:
const sortSlots = (slots: CalendarSlot[]) => {

    const compare = (a: CalendarSlot, b: CalendarSlot) => {
        if(new Date(a.dateTime).getTime() < new Date(b.dateTime).getTime()){
          return -1
        }
        if(new Date(a.dateTime).getTime() > new Date(b.dateTime).getTime()){
          return 1
        }
        return 0
    }

    return slots.sort(compare)

}

//check in:
export const useAppointmentCheckIn = (appointmentId: string) => useMutationHandler<boolean>({
    query: `
        mutation checkedInAppointment (

            $id: String!,
            $note: String!

        ) {
            checkedInAppointment (

                id: $id,
                note: $note

            ){
                id
            }
        }
    `,
    variables: {
        id: appointmentId,
        note: ''
    },
    onSuccess: json => json.data.checkedInAppointment.id === appointmentId
})

//resend email:
export const useAppointmentResendConfirmEmail = (appointmentId: string) => useMutationHandler<boolean>({
    query: `
        mutation resendAppointmentConfirmation (
            $id: String!
        ) {
            resendAppointmentConfirmation (
                id: $id
            ){
                message
            }
        }
    `,
    variables: {
        id: appointmentId
    },
    onSuccess: json => json.data.resendAppointmentConfirmation.message === 'Done'
})

//no show:
export const useAppointmentNoShow = (appointmentId: string) => useMutationHandler<boolean>({
    query: `
        mutation noShowAppointment (

            $id: String!,
            $note: String!

        ) {
            noShowAppointment (

                id: $id,
                note: $note

            ){
                id
            }
        }
    `,
    variables: {
        id: appointmentId,
        note: ''
    },
    onSuccess: json => json.data.noShowAppointment.id === appointmentId
})

export const useAppointmentCancel = (appointmentId: string, refund: boolean) => useMutationHandler<boolean>({
    query: `
        mutation cancelAppointment (

            $id: String!,
            $request: CancelAppointmentRequest!

        ) {
            cancelAppointment (

                id: $id,
                request: $request

            ){
                message
            }
        }
    `,
    variables: {
        id: appointmentId,
        request: {
            refundMoney: refund,
            content: null
        }
    },
    onSuccess: json => json.data.cancelAppointment.message === 'Done'
})
