import { useTranslate, useWindowDimensions } from 'utils'
import { ServiceLocation, ServiceLocationResources } from '../../../types'
import { useRef, useState } from 'react'
import { HorizontalContainer, VerticalContainer } from '../../styled'
import { ServiceResourceSelect } from '../service-resource-select/service-resource-select'
import { Button, theme, Typography } from 'antd'
import { useField } from 'react-final-form'
import { ResourceTypeSelect } from '../../resource/resource-type-select/resource-type-select'
const { Title, Paragraph } = Typography

type Props = {
    selectedLocation: ServiceLocation
    onSave: (resources: ServiceLocationResources[]) => void
    onCancel: () => void
}

export const ServiceResourceConfigurator = ({ selectedLocation, onSave, onCancel }: Props) => {

    //hooks:
    const { token: { padding, paddingSM, paddingLG, paddingXL, marginXS, marginLG } } = theme.useToken()
    const { __ } = useTranslate()
    const { SM } = useWindowDimensions()
    const headerRef = useRef<HTMLDivElement>(null)

    //fields:
    const { input: { value: title } } = useField('title')

    //state:
    const [ serLocResources, setSerLocResources ] = useState<ServiceLocationResources[]>(selectedLocation.resources)

    const handleSave = (serLocResources: ServiceLocationResources[]) => {
        onSave(cleanupEmptyTypes(serLocResources))
    }

    const cleanupEmptyTypes = (serLocResources: ServiceLocationResources[]) => {
        const slr = [...serLocResources]
        return slr.filter(slr => slr.resources.length)
    }

    return (
        <VerticalContainer style={{ height: '100%', gap: 0 }}>

            {/* header */}
            <VerticalContainer ref={headerRef} style={{ padding: SM ? paddingXL : padding }}>

                <HorizontalContainer>
                    <Title level={3} style={{ margin: 0, fontWeight: 'normal' }}>
                        {__('service_at_location', { service: title, location: selectedLocation.name })}
                    </Title>
                </HorizontalContainer>

                <Paragraph type='secondary' style={{ margin: 0 }}>
                    {__`manage_resources_and_availability_for_the_service_at_this_location`}
                </Paragraph>

            </VerticalContainer>

            <VerticalContainer style={{
                height: `calc(100% - ${headerRef.current?.scrollHeight ?? 100}px)`,
                justifyContent: 'space-between',
                gap: 0
            }}>
                
                <VerticalContainer style={{ overflowY: 'auto', gap: marginXS }}>

                    <HorizontalContainer style={{ padding: `0 ${SM ? paddingXL : padding}px`, alignItems: 'flex-start' }}>

                        <VerticalContainer>

                            <Title level={5} style={{ margin: 0 }}>
                                {__`resources`}
                            </Title>

                            <Paragraph type='secondary' style={{ margin: 0 }}>
                                {__`require_specific_resources_when_performing_this_service`}
                            </Paragraph>

                        </VerticalContainer>

                        {/* select */}
                        <ResourceTypeSelect
                            placeholder={__`add_a_resource`}
                            value={null}
                            clearSearchAfterSelect
                            onResourceTypeSelect={type => {
                                if(type.id){
                                    setSerLocResources([
                                        ...serLocResources,
                                        {
                                            type,
                                            blocksDuringAppointment: false,
                                            isSelectable: false,
                                            hideAnyAvailable: false,
                                            resources: []
                                        }
                                    ])
                                }
                            }}
                            style={{ width: '100%', maxWidth: 200 }}
                        />

                    </HorizontalContainer>

                    <VerticalContainer
                        style={{
                            padding: `${paddingSM}px ${SM ? paddingXL : 0}px`,
                            gap: marginLG,
                            flex: 1,
                            overflowY: 'auto'
                        }}
                    >

                        {serLocResources.map((slr, i) => (
                            <ServiceResourceSelect
                                key={i}
                                SLRs={serLocResources}
                                index={i}
                                onChange={slr => {
                                    const tmpSlr = [...serLocResources]
                                    tmpSlr[i] = slr
                                    setSerLocResources([...tmpSlr])
                                }}
                            />
                        ))}

                    </VerticalContainer>

                </VerticalContainer>

                <HorizontalContainer style={{
                    padding: `${SM ? paddingLG : padding}px ${SM ? paddingXL : padding}px`,
                    justifyContent: 'flex-end',
                    gap: marginXS
                }}>

                    <Button
                        type='default'
                        size='large'
                        title={__`cancel`}
                        onClick={onCancel}
                    >
                        {__`cancel`}
                    </Button>

                    <Button
                        type='primary'
                        size='large'
                        title={__`save`}
                        onClick={() => handleSave(serLocResources)}
                    >
                        {__`save`}
                    </Button>


                </HorizontalContainer>
            
            </VerticalContainer>

        </VerticalContainer>
    )

}
