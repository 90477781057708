import { Typography, theme } from 'antd'
import { FormCheckbox, FormGroupCheckbox, FormInput, FormRadio, FormSelect } from 'components/form-inputs'
import { FormSection } from 'components/form-section/form-section'
import { VerticalContainer, Message, NotFound } from 'components/styled'
import { Field, useField } from 'react-final-form'
import { NullableField, useTranslate } from 'utils'
import { experienceLanguageOptions, timezones } from 'data'
import { useConfig } from 'api'
import { Feature } from 'types'
const  { Text } = Typography

export const SettingsStorefront = () => {

    //hooks:
    const { token: { marginXXS, margin } } = theme.useToken()
    const { __ } = useTranslate()
    const { data: config } = useConfig()
    const hasReservation = config && config.features.includes(Feature.RESERVATION)
    const hasRemoveBranding = config && config.features.includes(Feature.REMOVE_BRANDING)

    //field:
    const { input: { value: locale } } = useField('locale')
    const { input: { value: timezone } } = useField('timezone')
    const { input: { value: timeFormat } } = useField('timeFormat')
    const { input: { value: dateFormat } } = useField('storefrontDateFormat')

    //getDateExample:
    const getDateExample = (format: string | null) => {

        if(format){

            const now       = new Date()
            const year      = now.getFullYear()
            const month     = now.toLocaleString([locale, 'en-US'], {month: 'numeric'})
            const monthName = now.toLocaleString([locale, 'en-US'], {month: 'short'})
            const day       = now.getDate()
            let   hour      = now.toLocaleString([locale, 'en-US'], { hour: '2-digit', hour12: (timeFormat === '12') })
            const minute    = Number(now.toLocaleString([locale, 'en-US'], { minute: '2-digit' }))

            let AmPm = ''
            if(timeFormat === '12' && format.includes('hh')){
                const translatedAm = __`am`
                const translatedPm = __`pm`
                AmPm = hour.includes(translatedAm) ? ` ${translatedAm}` : ` ${translatedPm}`
                hour = hour.replace(AmPm, '')
            }

            let example = format
            example = example.replaceAll('dd', day.toString())
            example = example.replaceAll('MM', month)
            example = example.replaceAll('NN', monthName)
            example = example.replaceAll('yyyy', year.toString())
            example = example.replaceAll('hh', hour)
            example = example.replaceAll('mm', minute > 9 ? minute.toString() : `0${minute}`) // the toLocaleString() doesn't return just the minute in 2-digits!
            example = example + AmPm

            return example

        }else{
            return '-'
        }
        
    }

    return config ? (
        <VerticalContainer style={{ gap: margin }}>

            <FormSection
                title={__`booking_experience`}
                childrenWrapperStyle={{ display: 'flex', flexDirection: 'column', gap: margin }}
            >
                
                {/* calendar experience */}
                {config.features.includes(Feature.EXPERIENCE_SELECTOR) && (
                    <Field
                        name='calendarExperience'
                        type='select'
                        title={__`calendar_experience`}
                        description={__`the_v2_calendar_is_not_yet_available_for_everyone`}
                        component={FormSelect}
                        options={[
                            {value: 'CLASSIC',  label: 'Sesami Classic'},
                            {value: 'V2'     ,  label: 'Sesami V2'     }
                        ]}
                        disabled={!hasReservation}
                    />
                )}

                {/* language */}
                <Field
                    name='locale'
                    title={__`storefront_language`}
                    type='select'
                    showSearch
                    filterOption={(input: string, option: { value: string, label: string}) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    component={FormSelect}
                    options={experienceLanguageOptions}
                    notFoundContent={<NotFound/>}
                />
                
                {/* time format */}
                <Field
                    name='timeFormat'
                    title={__`time_format`}
                    type='select'
                    options={[
                        {value: '12', text: __`12_hours`, description: __`example_02_00_pm`},
                        {value: '24', text: __`24_hours`, description: __`example_14_00`},
                    ]}
                    component={FormRadio}
                    maxWidth={350}
                    breakPoint={150}
                />

                {/* dateFormat */}
                <VerticalContainer>
                    
                    <NullableField
                        name='storefrontDateFormat'
                        title={__`date_format`}
                        placeholder="dd NN yyyy hh:mm"
                        component={FormInput}
                        description={__`date_explanation`}
                    />
                    
                    <Text>
                        {`> `}{__`example`}{`: `}{getDateExample(dateFormat)}
                    </Text>

                </VerticalContainer>

                {/* timezone */}  
                <Field
                    name='hideTimezoneFromStorefront'
                    type='checkbox'
                    text={__`hide_timezone_from_storefront_calendar`}
                    description={__(
                        'available_times_will_be_shown_in_timezone_timezone',
                        { timezone: findTimezoneLabel(timezone) }
                    )}
                    component={FormCheckbox}
                />
                
                {/* remove branding */}
                <Field
                    name='removeBranding'
                    type='checkbox'
                    text={
                        <>
                            {__`remove_sesami_branding`}
                            {!hasRemoveBranding && <Message type='warning' message={__`this_feature_is_not_available_due_to_your_plan`}/>}
                        </>
                    }
                    description={__`if_selected_sesami_s_name_would_be_hidden`}
                    component={FormCheckbox}
                    disabled={!hasRemoveBranding}
                />

            </FormSection>

            <FormSection
                title={__`reservation`}
                childrenWrapperStyle={{ display: 'flex', flexDirection: 'column', gap: margin }}
            >
                
                {/* reservation period */}
                <Field
                    name='reservationPeriod'
                    type='select'
                    title={
                        <>
                            {__`reservation_period`}
                            {!hasReservation && <Message type='warning' message={__`this_feature_is_not_available_due_to_your_plan`}/>}
                        </>
                    }
                    description={__`If_enabled_a_slot_will_be_blocked_for_the_specified_duration_upon_customers_selecting_a_time_and_confirming`}
                    component={FormSelect}
                    options={[
                        {value: 0,  label: `${__`disabled`  }` },
                        {value: 5,  label: `${__`5_minutes` }` },
                        {value: 10, label: `${__`10_minutes`}` },
                        {value: 15, label: `${__`15_minutes`}` },
                        {value: 30, label: `${__`30_minutes`}` },
                        {value: 60, label: `${__`60_minutes`}` }
                    ]}
                    disabled={!hasReservation}
                />

            </FormSection>

            <FormSection
                title={__`instant_booking`}
                childrenWrapperStyle={{ display: 'flex', flexDirection: 'column', gap: margin }}
            >

                {/* instant booking */}
                <Field
                    name='instantBooking.requiredFields'
                    title={__`instant_booking_required_fields`}
                    options={[
                        { value: 'firstName', label: `First name` },
                        { value: 'lastName' , label: `Last name`  },
                        { value: 'email'    , label: `Email`      },
                        { value: 'phone'    , label:  `Phone`     }
                    ]}
                    description={
                        <>
                            {__`choose_which_fields_you_d_like_to_collect_for_instant_booking_selected_fields_will_be_required`}
                            <Message type='warning' message={__`less_required_information_usually_leads_to_better_conversion_we_recommended_only_selecting_what_is_necessary`}/>
                        </>
                    }
                    style={{ flexDirection: 'column', gap: marginXXS }}
                    component={FormGroupCheckbox}
                />

            </FormSection>

        </VerticalContainer>
    ) : null

}

const findTimezoneLabel = (timezone: string) => {
    let label = ''
    timezones.map(tz => {
        if(tz.value === timezone){
            label = tz.label
        }
    })
    return label
}
