import { FormCheckbox, FormInput } from 'components/form-inputs'
import { FormSection } from 'components/form-section/form-section'
import { Message, VerticalContainer } from 'components/styled'
import { Field, useField } from 'react-final-form'
import { NullableField, useTranslate } from 'utils'
import { theme } from 'antd'

export const ResourceNotification = () => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { marginSM } } = theme.useToken()
    const { input: { value: email } } = useField('email')
    
    return (
        <FormSection title={__`notifications`}>
            
            <VerticalContainer style={{ gap: marginSM }}>

                {/* checkbox */}
                <Field
                    name="notificationEmailStatus"
                    type="checkbox"
                    text={
                        <>
                            {__`send_email_notification_on_appointment_events`}
                            {email === '' && <Message type='warning' message={__`insert_a_valid_email_to_enable_it`}/>}
                        </>
                    }
                    disabled={email === ''}
                    component={FormCheckbox}
                    description={__`an_email_with_a_calendar_event_will_be_sent_when_an_appointment_is_created_or_updated_your_customer_will_be_added_as_an_attendee`}
                />

                {/* event description */}
                <NullableField
                    name="eventDescription"
                    component={FormInput}
                    title={__`event_note`}
                    placeholder={email === '' ? __`insert_a_valid_email_to_enable_it` : __`please_join_the_meeting_using_this_link_https_zoom_us`}
                    description={__`this_note_will_be_included_on_all_event_notifications_booked_with`}
                    style={{ width: '100%' }}
                    disabled={email === ''}
                />

            </VerticalContainer>

        </FormSection>
    )

}
