import { useEffect, useState } from 'react'
import { useMe, useProfileUpdate } from 'api'
import { Me, UpdateUserFormSchema } from 'types'
import { CloudUploadOutlined } from '@ant-design/icons'
import { Loading, PageContentWrapper, PageError, VerticalContainer } from 'components/styled'
import { getShopId, isNil, queryClient, useTranslate, useWindowDimensions, validateFormValues } from 'utils'
import { Form } from 'react-final-form'
import { message, theme } from 'antd'
import { PageHeader } from 'components/page-header/page-header'
import { ProfileInfo } from 'components/profile/profile-info/profile-info'
import { ProfileLoginStrategy } from 'components/profile/profile-login-strategy/profile-login-strategy'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const Profile = () => {

    //hooks:
    const { __, isLoading: isTranslationLoading } = useTranslate()
    const [ searchParams ] = useSearchParams()

    //message after translation loading:
    useEffect(() => {
        if(!isTranslationLoading && searchParams.get('message') === 'googleSynced'){
            message.success(__`your_google_account_has_been_synced`)
        }
    }, [isTranslationLoading])
    
    //me:
    const { data: me, isLoading } = useMe()

    //render:
    return isLoading ? <Loading/> : !me ? <PageError/> : <UserFormContent me={me}/>

}

type ContentProps = {
    me: Me
}

export const UserFormContent = ({ me }: ContentProps) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { padding, margin, marginLG, marginXL } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const navigate = useNavigate()

    //state:
    const [ initialValues, setInitialValues ] = useState(me)
    const [ savingValues, setSavingValues ] = useState(me)

    //mutation:
    const { mutate: updateUser, isLoading, isSuccess, data: mutationResponse } = useProfileUpdate(savingValues)

    //save:
    const save = (values: Me) => {
        setSavingValues(values)
        updateUser()
    }

    //after update:
    useEffect(() => {
        if(isSuccess && !isNil(savingValues) && mutationResponse){
            setInitialValues(savingValues)
            if(initialValues.locale !== savingValues.locale){ // language updated
                const shopId = getShopId()
                queryClient.resetQueries(['config', shopId])
                queryClient.resetQueries(['me'])
            }else{
                queryClient.invalidateQueries(['me'])
            }
            message.success(__`profile_updated`)
        }
    }, [isLoading])

    return (
        <PageContentWrapper>

            <Form
                initialValues={initialValues}
                subscription={{ dirty: true }}
                onSubmit={(values: Me) =>
                    UpdateUserFormSchema().validate(values).then(
                        data => save(data as Me)
                    )
                }
                validate={validateFormValues(UpdateUserFormSchema())}
                render={({ form, dirty, handleSubmit }) => (

                    <VerticalContainer style={{ gap: margin }}>

                        {/* header */}
                        <PageHeader
                            title={__`profile`}
                            description={__`manage_your_profile`}
                            primaryButton={{
                                name: __`save`,
                                icon: <CloudUploadOutlined/>,
                                mode: isLoading ? 'loading' : dirty ? 'normal': 'disabled',
                                onClick: handleSubmit
                            }}
                            secondaryButton={dirty ? {
                                name: __`discard`,
                                mode: isLoading ? 'disabled' : 'normal',
                                onClick: () => form.reset()
                            } : undefined}
                            backButton={{
                                name: __`back`,
                                onClick: () => navigate('/')
                            }}
                            isFormDirty={dirty}
                        />

                        <VerticalContainer style={{ padding: SM ? `0 ${padding}px` : 0, gap: SM ? marginXL : marginLG }}>

                            {/* info */}
                            <ProfileInfo disableEmail={true} hideRest={false}/>
                            
                            {/* login strategy */}
                            {isNil(me.loginStrategy) && <ProfileLoginStrategy/>}

                        </VerticalContainer>

                    </VerticalContainer>
                    
                )}
            />

        </PageContentWrapper>
    )
    
}
