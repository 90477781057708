import { theme } from 'antd'
import { PageHeader } from 'components/page-header/page-header'
import { PlusOutlined, RightOutlined } from '@ant-design/icons'
import { Label, PageContentWrapper, VerticalContainer } from 'components/styled'
import { getShopId, getUserName, setCacheManually, useTranslate, useWindowDimensions } from 'utils'
import { useConfig, useUsers } from 'api'
import { useNavigate } from 'react-router-dom'
import { User } from 'types'
import { Avatar } from 'components/avatar/avatar'
import { VerticalPaginatedList } from 'components/vertical-paginated-list/vertical-paginated-list'
import { useState } from 'react'
import { FormSection } from 'components/form-section/form-section'

export const UserList = () => {

    //hooks:
    const { token: {
        marginXXS,
        margin,
        paddingXXS,
        paddingXS,
        paddingSM,
        padding,
        paddingMD,
        borderRadius,
        fontSizeSM
    }} = theme.useToken()
    const { __ } = useTranslate()
    const navigate = useNavigate()
    const { SM } = useWindowDimensions()
    const { data: config } = useConfig()

    //state:
    const [ search, setSearch ] = useState<string | null>(null)
    
    //services:
    const {
        data: users,
        pageNum,
        totalPages,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useUsers(search, 15)
    
    return (
        <PageContentWrapper>

            <VerticalContainer style={{ gap: SM ? margin : marginXXS }}>

                {/* header */}
                <PageHeader
                    title={__`users`}
                    description={__`manage_all_the_users`}
                    primaryButton={{
                        name: SM ? __`create_user` : __`create`,
                        title: (config && config.canSetPermissions) ? undefined : __`you_dont_have_access_to_create_a_user`,
                        icon: <PlusOutlined/>,
                        mode: (config && config.canSetPermissions) ? 'normal' : 'disabled',
                        onClick: () => navigate('/user/new')
                    }}
                />

                <div style={{ padding: SM ? `0 ${padding}px` : 0 }}>
                
                    <FormSection
                        title={SM ? __`users` : undefined}
                        childrenWrapperStyle={{ padding: SM ? `0 ${paddingMD}px` : 0 }}
                        searchInput={{
                            placeholder: __`search_users`,
                            onSearch: setSearch
                        }}
                    >

                        {/* list */}
                        <VerticalPaginatedList
                            isFirstLoading={isLoading}
                            isForbidden={users === null}
                            isError={users === false}
                            notFound={(users && users.length === 0) ?? false}
                            totalPages={totalPages}
                            pageNum={pageNum}
                            isPreviousData={isPreviousData}
                            isNextDisabled={isNextDisabled}
                            isPreviousDisabled={isPreviousDisabled}
                            fetchNext={fetchNext}
                            fetchPrevious={fetchPrevious}
                        >
                            {users && users.map((user: User) => {
                                const userName = getUserName(user)
                                return (
                                    <Avatar
                                        key={user.id}
                                        name={userName}
                                        description={user.email}
                                        image={user.image.path}
                                        extraIcon={<RightOutlined style={{ fontSize: fontSizeSM }}/>}
                                        tag={user.permissionsAccepted === false ? <Label text='Pending' type='warning' size='tiny'/> : undefined}
                                        style={{
                                            width: '100%',
                                            padding: SM ? `${paddingXXS}px ${paddingSM}px ${paddingXXS}px ${paddingXS}px` : `${paddingXXS}px ${paddingMD}px`,
                                            borderRadius: SM ? borderRadius : 0
                                        }}
                                        onClick={() => {
                                            setCacheManually(['user', user.id, getShopId()], user)
                                            navigate(`/user/${user.id}`)
                                        }}
                                    />
                                )
                            })}
                        </VerticalPaginatedList>

                    </FormSection>

                </div>

            </VerticalContainer>
            
        </PageContentWrapper>
    )
    
}
