import { MainWrapper, TitleWrapper } from './appointment-details-header.style'
import { Typography, theme } from 'antd'
import { RGBtoRGBA, isNil } from 'utils'
const { Text } = Typography

type Props = {
    title: string
    description?: string
    tag?: string
    colorRGB?: string
}

export const AppointmentDetailsHeader = ({ title, description, tag, colorRGB }: Props) => {

    //theme:
    const { token: {
        fontSizeHeading3,
        fontSizeSM,
        paddingXS,
        paddingXXS,
        borderRadius,
        colorTextSecondary,
        colorTextDescription
    }} = theme.useToken()

    return (
        <MainWrapper>
            
            <TitleWrapper>

                {/* title */}
                <Text
                    title={title}
                    style={{
                        fontSize: fontSizeHeading3,
                        fontFamily: 'inter-medium',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                    }}
                >
                    {title}
                </Text>

                {/* tag */}
                {tag &&
                    <Text
                        style={{
                            padding: `${paddingXXS}px ${paddingXS}px`,
                            backgroundColor: !isNil(colorRGB) ? RGBtoRGBA(colorRGB!, .1) : 'transparent',
                            borderRadius: `${borderRadius}px`,
                            fontSize: fontSizeSM,
                            whiteSpace: 'nowrap',
                            color: !isNil(colorRGB) ? RGBtoRGBA(colorRGB!, .9) : colorTextSecondary,
                        }}
                    >
                        {tag}
                    </Text>
                }

            </TitleWrapper>

            {/* description */}
            <Text
                style={{
                    fontSize: fontSizeSM,
                    color: colorTextDescription
                }}
            >
                {description}
            </Text>

        </MainWrapper>
    )
}
