import { Typography, theme } from 'antd'
import { FormInput } from 'components/form-inputs'
import { VerticalContainer } from 'components/styled'
import { useField } from 'react-final-form'
import { NullableField, useTranslate, useWindowDimensions } from 'utils'
const  { Title } = Typography

export const ResourceInfo = () => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { paddingXXS, marginSM, marginXXS } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { input: { value: calendarSyn } } = useField('calendarSync')

    return (
        <VerticalContainer style={{ gap: marginSM }}>

            {/* title */}
            <Title
                level={5}
                style={{
                    paddingTop: SM ? 0 : paddingXXS,
                    margin: `${marginXXS}px 0`
                }}
            >
                {__`info`}
            </Title>
            
            {/* name */}
            <NullableField
                name='name'
                title={__`name`}
                placeholder={__`first_last_name_example`}
                component={FormInput}
            />
            
            {/* email */}
            <NullableField
                name='email'
                title={__`email`}
                placeholder="my@email.com"
                disabled={calendarSyn?.calendarSyncStatus !== null}
                description={calendarSyn?.calendarSyncStatus ? __`to_change_the_email_you_have_to_revoke_the_google_calendar_sync` : null}
                component={FormInput}
            />

            {/* mobile */}
            <NullableField
                name='mobile'
                title={__`mobile`}
                placeholder={__`mobile_example`}
                component={FormInput}
            />

            {/* description */}
            <NullableField
                name='description'
                title={__`bio`}
                placeholder={__`bio_sample`}
                component={FormInput}
            />

        </VerticalContainer>
    )

}
