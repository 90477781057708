import { Plan } from 'types'
import { usePaginationHandler } from 'utils'

export const planQuery = `
    id
    planId
    title
    name
    description
    price
    maxServices
    maxTeamMembers
    maxGroupAppointmentsSlots
    maxFlows
    listed
`

//usePlans:
export const usePlans = (search: string | null, limit: number) => usePaginationHandler<Plan[]>({
    query: `
        query getPlans (
            $searchTerm: String,
            $limit: Int!,
            $after: String,
            $before: String
        ) {
            getPlans (
                searchTerm: $searchTerm,
                limit: $limit,
                after: $after,
                before: $before
            ) {
                
                data {
                    ${planQuery}
                }

                totalItems
                remainingItems
                nextCursor
                previousCursor

            }
        }
    `,
    variables: {
        searchTerm: search,
        limit,
        after: null,
        before: null
    },
    cacheKey: ['plans'],
    limit,
    getResponse: json => json.data.getPlans,
    onSuccess: response => response.data
})
