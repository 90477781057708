import { Typography, theme } from 'antd'
import { FormCheckbox, FormSelect } from 'components/form-inputs'
import {FormSection} from 'components/form-section/form-section'
import { NotFound, VerticalContainer } from 'components/styled'
import { Field } from 'react-final-form'
import { useTranslate } from 'utils'
import { timezones } from 'data'
const  { Text, Paragraph } = Typography

export const SettingsStore = () => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { marginXXS, marginXS, margin } } = theme.useToken()

    return (
        <VerticalContainer style={{ gap: margin }}>

            <FormSection>

                {/* timezone */}
                <Field
                    name="timezone"
                    type="select"
                    notFoundContent={<NotFound/>}
                    options={timezones}
                    showSearch
                    filterOption={(input: string, option: { value: string, label: string}) => (
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    )}
                    component={FormSelect}
                    title={__`store_timezone`}
                />

            </FormSection>

            <FormSection title={__`notifications`}>

                {/* confirmation email */}
                <VerticalContainer style={{ gap: marginXS }}>
                    
                    <Field
                        name="emailCalendarEventToMerchant"
                        title={__`email_confirmation`}
                        type="checkbox"
                        text={__`send_email_confirmations_to_merchant`}
                        component={FormCheckbox}
                    />
                    
                    <Field
                        name="emailCalendarEventToCustomer"
                        type="checkbox"
                        text={<Text>{__`send_email_confirmations_to_customer`}</Text>}
                        description={
                            <Paragraph style={{ margin: `${marginXXS}px 0 0 0` }} type='secondary'>
                                {__`when_an_appointment_is_created_updated_an_email_including_a_calendar_event_will_be_sent_and_your_customer_will_be_added_as_an_attendee`}
                            </Paragraph>
                        }
                        component={FormCheckbox}
                    />

                </VerticalContainer>

            </FormSection>

        </VerticalContainer>

    )

}
