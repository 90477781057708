import styled from 'styled-components'
import { CALENDAR_PAGE_CELL_COVER_Z_INDEX, CALENDAR_PAGE_CELL_HEADER_Z_INDEX, CALENDAR_PAGE_CELL_TIMELINE_COLUMN_Z_INDEX } from 'z-indexes'

export const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: ${props => `${props.theme.marginXXS}px`};
`

export const Header = styled.div`
    width: 100%;
    display: flex;
    z-index: ${CALENDAR_PAGE_CELL_HEADER_Z_INDEX};
`

type ColumnProps = {
    totalColumns: number
}

export const Column = styled.div<ColumnProps>`
    width: ${props => `${100/props.totalColumns}%`};
    min-width: 150px;
    height: 100%;
    display: inline-block;
`

export const ResourceContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`

export const Content = styled.div`
    width: 100%;
    display: flex;
    position: relative;
`

type TimelineColumnProps = {
    isMobile: boolean
}

export const TimelineColumn = styled.div<TimelineColumnProps>`
    width: ${props => `${props.theme.marginXXS*(props.isMobile ? 16 : 12)}px`};
    padding-left: ${props => `${props.isMobile ? props.theme.padding : 0}px`};
    margin-left: ${props => `${props.isMobile ? -props.theme.padding : 0}px`};
    height: 100%;
    background-color: ${props => props.theme.colorBgContainer};
    border-right: solid 1px ${props => props.theme.colorBorderSecondary};
    display: flex;
    flex-direction: column;
    position: sticky;
    left: 0;
    z-index: ${CALENDAR_PAGE_CELL_TIMELINE_COLUMN_Z_INDEX};
    & > div:nth-last-child(1){ // hide the last hour badge
        & > div{
            & > div:last-child{
                &::after{
                    display: none
                }
            }
        }
    }
`

export const Cover = styled.div`
    border-left: ${props => `solid 1px ${props.theme.colorBorderSecondary}`};
    min-height: 100%;
    background-color: ${props => props.theme.colorBgContainer};
    position: sticky;
    right: 0;
    z-index: ${CALENDAR_PAGE_CELL_COVER_Z_INDEX};
`

export const InfoWrapper = styled.div`
    flex: 1;
    position: relative;
`

export const InfoContainer = styled.div`
    width: 100%;
    height: 100%;
    & > div:last-child{
        & > div{
            border: none
        }
    }
`

export const InfoColumn = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: solid 1px ${props => props.theme.colorBorderSecondary};
    position: relative;
`

export const TimeBoxColumn = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    & > div:last-child{
        border: none
    }
`

type TimeBoxProps = {
    scale: number
}

export const TimeBox = styled.div<TimeBoxProps>`
    width: 100%;
    height: ${props => `${props.scale*60}px`}; //60 minutes
    border-bottom: solid 1px ${props => props.theme.colorBorderSecondary};
    display: flex;
    flex-direction: column;
    justify-content: center;
    ::after{
        content: "";
        width: 100%;
        height: 1px;
        background-color: ${props => props.scale >= 3 ? props.theme.colorBorderSecondary : 'transparent'}
    }
`

export const SlotsColumn = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
`
