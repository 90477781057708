import { useEffect } from 'react'
import { getShopId, queryClient, useTranslate } from 'utils'
import { useField } from 'react-final-form'
import { FormRemove } from 'components/form-remove/form-remove'
import { useNavigate } from 'react-router-dom'
import { useRemoveLocation } from 'api'
import { message } from 'antd'

export const LocationRemove = () => {

    //hooks:
    const { __ } = useTranslate()
    const navigate = useNavigate()

    //field:
    const { input: { value: id } } = useField('id')
    const { input: { value: name } } = useField('name')

    //mutation:
    const { mutate, isLoading, data: response } = useRemoveLocation(id)

    //after remove:
    useEffect(() => {
        if(response === true){
            queryClient.invalidateQueries(['locations', getShopId()])
            message.success(__`location_removed`)
            navigate('/location')
        }else if(response === 'assignedToService'){
            message.error(__`location_is_assigned_to_a_service`)
        }else if(response === false){
            message.error(__`oops_something_went_wrong`)
        }
    }, [response])
    
    return (
        <FormRemove
            title={`${__`remove`} "${name}"`}
            message={__`all_settings_will_be_deleted_this_action_cant_be_undone`}
            description={__`the_location_should_not_be_assigned_to_any_services`}
            isLoading={isLoading}
            onRemove={mutate}
        />
    )

}
