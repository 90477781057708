import { theme } from 'antd'
import { FormInput, FormSelect } from 'components/form-inputs'
import { FormSection } from 'components/form-section/form-section'
import { NotFound, VerticalContainer } from 'components/styled'
import { adminLanguageOptions } from 'data'
import { Field } from 'react-final-form'
import { NullableField, useTranslate } from 'utils'

type Props = {
    disableEmail: boolean
    hideRest: boolean
}

export const ProfileInfo = ({ disableEmail, hideRest }: Props) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { marginSM } } = theme.useToken()

    return (
        <FormSection title={__`personal_info`}>

            <VerticalContainer style={{ gap: marginSM }}>
                
                {/* email */}
                <Field
                    name="email"
                    title={__`email`}
                    placeholder="my@email.com"
                    disabled={disableEmail}
                    component={FormInput}
                />

                {!hideRest && (

                    <>

                        {/* firstName */}
                        <NullableField
                            name="firstName"
                            title={__`first_name`}
                            placeholder={__`name_example`}
                            component={FormInput}
                        />
                        
                        {/* lastName */}
                        <NullableField
                            name="lastName"
                            title={__`last_name`}
                            placeholder={__`last_name_example`}
                            component={FormInput}
                        />

                        {/* language */}
                        <Field
                            name="locale"
                            type="select"
                            title={__`language`}
                            options={adminLanguageOptions}
                            component={FormSelect}
                            notFoundContent={<NotFound/>}
                            showSearch
                            filterOption={(input: string, option: { value: string, label: string}) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        />

                    </>
                )}

            </VerticalContainer>

        </FormSection>
    )

}
