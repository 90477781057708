import { CalendarWrapper, ColumnContainer, LeftColumn, RightColumn, ScaleWrapper } from './booking-form.style'
import { BookingAction, BookingPageState, bookingPageDefaultState, pageStateReducer } from '../booking-page/booking-page'
import { PageHeader } from 'components/page-header/page-header'
import { RightOutlined } from '@ant-design/icons'
import { Loading, PageContentWrapper, PageError, VerticalContainer } from 'components/styled'
import { isNil, useTranslate , min, useWindowDimensions, TranslateFunction, getTimeFromDateTimeString, useElementScrollBreak } from 'utils'
import { useEffect, useReducer, useRef } from 'react'
import { BookingCalendarNavigation, setStartAndEnd } from 'components/booking/booking-calendar-navigation/booking-calendar-navigation'
import { BookingCustomer } from 'components/booking/booking-customer/booking-customer'
import { BookingService, ServicePreset } from 'components/booking/booking-service/booking-service'
import { BookingResource } from 'components/booking/booking-resource/booking-resource'
import { FormSection } from 'components/form-section/form-section'
import { Scale } from 'components/calendar/scale/scale'
import { theme } from 'antd'
import { BookingCalendar } from 'components/booking/booking-calendar/booking-calendar'
import { Appointment, Config } from 'types'
import { useConfig, useCreateDraftOrder, useRescheduleAppointment, useSettings } from 'api'
import { BOOKING_SCALE_LCL_STR_KEY, BOOKING_DEFAULT_SCALE } from 'app.constants'

type Props = {
    servicePreset: ServicePreset
    appointment?: Appointment
    onSave: (bookingId?: string) => void
    onBack: () => void
}

export const BookingForm = ({ servicePreset, appointment, onSave, onBack }: Props) => {

    const isReschedule = !isNil(appointment)

    //hooks:
    const calendarContainer = useRef<HTMLInputElement>(null)
    const { token: { paddingXXS, padding, paddingXL, margin, marginLG } } = theme.useToken()
    const { width, SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const { data: config, isLoading: isConfigLoading } = useConfig()
    const { data: settings, isLoading: isSettingsLoading } = useSettings()

    //reducer:
    const [ pageState, pageStateDispatch ] = useReducer(pageStateReducer, {...bookingPageDefaultState, scale: getScaleFromLocalStorage()})

    //window width:
    useEffect(() => {
        if(calendarContainer.current !== null){
            const calendarContainerWidth = calendarContainer.current.offsetWidth
            const columnsNumber = min(Math.floor(calendarContainerWidth / 200), 7)
            if(columnsNumber !== pageState.numberOfDays){

                //number of days:
                pageStateDispatch({ type: BookingAction.SET_NUMBER_OF_DAYS, payload: { numberOfDays: columnsNumber } })
                
                //end date:
                setStartAndEnd(pageState.startDate, columnsNumber, pageStateDispatch)

            }
        }
    }, [width, calendarContainer.current, settings])

    //save draft:
    const { mutate: createDraft, isLoading: isCreateDraftLoading, data: createDraftResponse } = useCreateDraftOrder(
        pageState.service?.id ?? '',
        pageState.variant?.id ?? '',
        pageState.participants,
        pageState.resources.map(r => r?.id ?? ''),
        pageState.customer?.id ?? '',
        new Date(pageState.slot?.startDateTime ?? '1991-04-18 00:00'),
        getTimeFromDateTimeString(pageState.slot?.startDateTime ?? '') ?? ''
    )

    //reschedule:
    const { mutate: reschedule, isLoading: isRescheduleLoading, data: rescheduleResponse } = useRescheduleAppointment(
        appointment?.id ?? '',
        pageState.resources.map(r => r?.id ?? ''),
        pageState.slot?.startDateTime ?? ''
    )

    //handleMainButtonClick:
    const handleMainButtonClick = () => {
        if(isReschedule){
            reschedule()
        }else{
            createDraft()
        }
    }

    //handle crate draft response:
    useEffect(() => {createDraftResponse && onSave(createDraftResponse)}, [isCreateDraftLoading])

    //handle reschedule response:
    useEffect(() => {rescheduleResponse && onSave()}, [isRescheduleLoading])
    useEffect(() => {
        if(appointment){
            pageStateDispatch({ type: BookingAction.SET_START_DATE, payload: { startDate: new Date(appointment.datetime) } })
        }
    }, [appointment])

    //handleScaleChange:
    const handleScaleChange = (scale: number) => {
        pageStateDispatch({ type: BookingAction.SET_SCALE, payload: { scale: scale } })
        saveScaleToLocalStorage(scale)
    }
    
    return (
        (isConfigLoading || isSettingsLoading) ? <Loading/> :
        (!config || !settings) ? <PageError/> : (

            <PageContentWrapper fullWidth noBottomPadding>

                <VerticalContainer style={{ gap: margin }}>

                    {/* header */}
                    <div> {/* stops the header to be sticky on tablet */}
                        <PageHeader
                            title={isReschedule ? __`reschedule_reassign` : __`booking`}
                            description={isReschedule ? __`change_your_appointment_time_or_resource` : __`book_an_appointment`}
                            primaryButton={{
                                name: getButtonLabel(config, pageState, __),
                                icon: (pageState.slot && pageState.customer) && <RightOutlined/>,
                                style: {
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    alignItems: 'center'
                                },
                                mode: (!pageState.slot || !pageState.customer) ? 'disabled' : (isCreateDraftLoading || isRescheduleLoading) ? 'loading' : 'normal',
                                onClick: handleMainButtonClick
                            }}
                            backButton={{
                                name: __`back`,
                                onClick: onBack
                            }}
                            isFormDirty={!isNil(pageState.slot)}
                            zIndex={SM ? undefined : 1}
                        />
                    </div>

                    <VerticalContainer style={{ padding: SM ? `0 ${padding}px` : 0, gap: marginLG }}>

                        <ColumnContainer>

                            {/* left column */}
                            <LeftColumn>

                                {/* service */}
                                <BookingService servicePreset={servicePreset} pageState={pageState} pageStateDispatch={pageStateDispatch}/>

                                {/* resource */}
                                <BookingResource pageState={pageState} pageStateDispatch={pageStateDispatch}/>

                                {/* customer */}
                                <BookingCustomer
                                    pageState={pageState}
                                    pageStateDispatch={pageStateDispatch}
                                    customerPreset={ // convert customer to external customer:
                                        appointment?.customer ? {
                                            id: appointment.customer.id ?? '',
                                            firstName: appointment.customer.name,
                                            lastName: null,
                                            phone: appointment.customer.phone,
                                            email: appointment.customer.email,
                                            image: appointment.customer.image
                                        } : null
                                    }
                                />

                            </LeftColumn>

                            {/* right column */}
                            <RightColumn>

                                <FormSection style={{
                                    paddingTop: paddingXXS,
                                    paddingBottom: SM ? paddingXXS : 0
                                }}>

                                    <CalendarWrapper ref={calendarContainer} style={{ gap: 0 }}>

                                        {/* calendar navigation */}
                                        <BookingCalendarNavigation pageState={pageState} pageStateDispatch={pageStateDispatch}/>
                                    
                                        {/* calendar */}
                                        {pageState.numberOfDays === 0 ? <Loading style={{ padding: paddingXL }}/> : (
                                            <BookingCalendar
                                                settings={settings}
                                                pageState={pageState}
                                                pageStateDispatch={pageStateDispatch}
                                            />
                                        )}

                                    </CalendarWrapper>

                                    {/* scale */}
                                    <BookingFormScale
                                        scale={pageState.scale}
                                        isFormDirty={!isNil(pageState.slot)} 
                                        onScaleChange={handleScaleChange}
                                    ></BookingFormScale>

                                </FormSection>

                            </RightColumn>

                        </ColumnContainer>

                    </VerticalContainer>
                
                </VerticalContainer>

            </PageContentWrapper>
            
        )
    )

}

type BookingFormScaleProps = {
    scale: number
    isFormDirty: boolean
    onScaleChange: (scale: number) => void
}

const BookingFormScale = ({ scale, isFormDirty, onScaleChange }: BookingFormScaleProps) => {
    
    //hooks:
    const { LG } = useWindowDimensions()
    const { isScrollBreak: isLayoutScrollOver75 } = useElementScrollBreak('layoutScrollContainer', 75)

    return (
        <ScaleWrapper bottom={(LG && isFormDirty && isLayoutScrollOver75) ? 57 : 0}>
            <Scale
                scale={scale}
                onScaleChange={onScaleChange}
            />
        </ScaleWrapper>
    )
    
}

//getButtonLabel
const getButtonLabel = (config: Config, pageState: BookingPageState, __: TranslateFunction) => {
    if(pageState.slot === null){
        return __`choose_a_slot`
    }else if(pageState.customer === null){
        return __`choose_a_customer`
    }else{
        const date = new Date(pageState.slot.startDateTime)
        return date.toLocaleString([config.locale, 'en-US'], { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })
    }
}

//getScaleFromLocalStorage:
const getScaleFromLocalStorage = () => {
    let scale = BOOKING_DEFAULT_SCALE
    try{
        const scaleString = localStorage.getItem(BOOKING_SCALE_LCL_STR_KEY)
        if(scaleString){
            scale = parseFloat(scaleString)
        }
    }catch(e){
        scale = BOOKING_DEFAULT_SCALE
    }
    return scale ? scale : BOOKING_DEFAULT_SCALE
}

//saveScaleToLocalStorage:
const saveScaleToLocalStorage = (scale: number) => {
    try{
        localStorage.setItem(BOOKING_SCALE_LCL_STR_KEY, scale.toString())
    }catch(e){e}
}
