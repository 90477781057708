import { Typography, theme } from 'antd'
import { useConfig } from 'api'
import { FormCheckbox, FormDatePicker } from 'components/form-inputs'
import { FormSection } from 'components/form-section/form-section'
import { Label, Message } from 'components/styled'
import { Field } from 'react-final-form'
import { NullableField, useTranslate } from 'utils'
import { Feature } from 'types'
const  { Text } = Typography

export const ServiceStorefront = () => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { marginXXS, margin } } = theme.useToken()
    const { data: config } = useConfig()

    const hasInstantBooking = config && config.features.includes(Feature.INSTANT_BOOKING)
    
    return (
        <FormSection
            title={__`storefront`}
            childrenWrapperStyle={{ display: 'flex', flexDirection: 'column', gap: margin }}
        >
                
            {/* Storefront start date */}
            <NullableField
                name="storefrontStartDate"
                title={__`storefront_start_date`}
                placeholder={__`select_a_date`}
                description={__`leave_it_blank_to_start_from_the_current_date_default`}
                component={FormDatePicker}
                parse={(value: any) => value ? value.format('YYYY-MM-DD') : null}
            />

            {/* instant booking */}
            <Field
                name="instantBooking"
                type="checkbox"
                text={
                    <>
                        <Text style={{ fontFamily: 'inter-medium' }}>{__`enable_instant_booking_for_this_service`}</Text>
                        <Label size='medium' type='info' text={__`beta`} style={{ marginLeft: marginXXS }}/>
                        {!hasInstantBooking && <Message type='warning' message={__`this_feature_is_not_available_due_to_your_plan`}/>}
                    </>
                }
                description={__`customers_can_complete_the_booking_in_the_sesami_pop_up_and_skip_the_checkout_recommended_for_free_or_post_paid_appointments`}
                component={FormCheckbox}
                disabled={!hasInstantBooking}
            />

        </FormSection>
    )

}
