import { useState } from 'react'
import { theme } from 'antd'
import { Avatar } from 'components/avatar/avatar'
import { objectAssign, useTranslate } from 'utils'
import { useResources } from 'api'
import { Loading, NotFound, PageError, WhiteShadowLoading } from 'components/styled'
import { SimpleResourceWithAvailabilities, Resource } from 'types'
import { FormSection } from 'components/form-section/form-section'
import { RESOURCE_SELECT_SHADOW_Z_INDEX } from 'z-indexes'
import { Container, SelectedResourceIndicator, Wrapper } from './resource-select.style'

type Props = {
    title?: string
    items?: SimpleResourceWithAvailabilities[]
    selectedId: string | null
    showAll?: boolean
    areItemsVisible?: boolean
    itemsNotVisibleMessage?: string
    wrapperStyle?: object
    contentWrapperStyle?: object
    onSelect: (resource: Resource | SimpleResourceWithAvailabilities | null) => void
}

export const ResourceSelect = ({
    title,
    items,
    selectedId,
    showAll = false,
    areItemsVisible = true,
    itemsNotVisibleMessage = '',
    wrapperStyle,
    contentWrapperStyle,
    onSelect
}: Props) => {

    //hooks:
    const { __ } = useTranslate()
    const { token: { paddingXXS, padding, paddingMD } } = theme.useToken()

    //state:
    const [ search, setSearch ] = useState<string | null>(null)
    
    //api:
    const fetchFromAPI = items === undefined
    const {
        data,
        pageNum,
        totalPages,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useResources(null, null, search, 15, fetchFromAPI)
    const resources = fetchFromAPI ? data : items

    return (
        <FormSection
            title={title ?? __`resource`}
            style={objectAssign({ paddingBottom: padding }, wrapperStyle)}
            childrenWrapperStyle={contentWrapperStyle}
            searchInput={{
                placeholder:__`search_resources`,
                onSearch: setSearch
            }}
            topNav={{
                pageNum: fetchFromAPI ? pageNum : areItemsVisible ? 1 : 0,
                totalPages: fetchFromAPI ? totalPages : areItemsVisible ? 1 : 0,
                isNextDisabled,
                isPreviousDisabled,
                onNext: fetchNext,
                onPrevious: fetchPrevious
            }}
        >

            <Container isEmpty={isLoading || (resources ? resources.length : 1) === 0}>
        
                {
                    (fetchFromAPI && isLoading) ? <Loading style={{ padding: padding }}/> :
                    !resources ? <PageError style={{ padding: paddingMD }}/> :
                    !areItemsVisible ? <NotFound message={itemsNotVisibleMessage} style={{ padding: paddingMD }}/> :
                    resources.length === 0 ? <NotFound message={search ? undefined : __`no_resources`} style={{ padding: paddingMD }}/> : (

                        <>
                            <Wrapper>

                                {/* all */}
                                {showAll &&
                                    <SelectedResourceIndicator
                                        key={'all'}
                                        isSelected={selectedId === null}
                                    >

                                        <Avatar
                                            name={__`all`}
                                            image={null}
                                            isVertical={true}
                                            style={{
                                                width: '80px',
                                                height: '100%',
                                                paddingLeft: paddingXXS,
                                                paddingRight: paddingXXS
                                            }}
                                            onClick={() => onSelect(null)}
                                        />

                                    </SelectedResourceIndicator>
                                }

                                {/* resources */}
                                {resources.map(resource =>

                                    <SelectedResourceIndicator
                                        key={resource.id}
                                        isSelected={selectedId === resource.id}
                                    >

                                        <Avatar
                                            name={resource.name}
                                            image={resource.image.path}
                                            isVertical={true}
                                            style={{
                                                width: '80px',
                                                height: '100%',
                                                paddingLeft: paddingXXS,
                                                paddingRight: paddingXXS
                                            }}
                                            onClick={() => onSelect(resource)}
                                        />

                                    </SelectedResourceIndicator>
                                    
                                )}

                            </Wrapper>

                            {isPreviousData && <WhiteShadowLoading style={{ zIndex: RESOURCE_SELECT_SHADOW_Z_INDEX }}/>}
                            
                        </>

                    )

                }

            </Container>

        </FormSection>
        
    )

}
