import { LOCATION_ID_LCL_STR_KEY, SHOP_ID_LCL_STR_KEY } from 'app.constants'

// an interface declaration to handle the window.shopId type:
declare global {
    interface Window {
        shopId: string | undefined
    }
}

//getShopId:
export const getShopId = () => {
    let id = ''
    try{
        id = localStorage.getItem(SHOP_ID_LCL_STR_KEY) ?? ''
    }catch(e){
        id = window.shopId ?? ''
    }
    return id
}

//updateShopId:
export const updateShopId = (id: string | null) => {
    try{
        if(id){
            localStorage.setItem(SHOP_ID_LCL_STR_KEY, id)
        }else{
            localStorage.removeItem(SHOP_ID_LCL_STR_KEY)
        }
        localStorage.removeItem(LOCATION_ID_LCL_STR_KEY)
        location.reload() //!tmp
    }catch(e){
        window.shopId = id ?? undefined
    }
}
