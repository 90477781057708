import { useEffect, useState } from 'react'
import { setIn } from 'final-form'
import { useMe, useSetPassword } from 'api'
import { VerticalContainer } from 'components/styled'
import { TranslateFunction, queryClient, useTranslate } from 'utils'
import { Field, Form } from 'react-final-form'
import { Button, theme } from 'antd'
import { FormPassword } from 'components/form-inputs'
import { object, ref, string } from 'yup'

//UserPasswordStrategyFormSchema:
const UserPasswordStrategyFormSchema = (__: TranslateFunction) => object({
    password: string().required(__`enter_the_password`),
    passwordRepeat: string().required(__`confirm_new_password`).oneOf([ref('password')], __`doesnt_match_the_password`)
})

//handleValidate:
const handleValidate = (schema: any) => async (values: { password: string, passwordRepeat: string }) => {
    if(typeof schema === 'function')
        schema = schema()
    try{
        await schema.validate(values, { abortEarly: false })
    }catch(err: any){
        const errors = err.inner.reduce((formError: object, innerError: any) => {
            return setIn(formError, innerError.path, innerError.message)
        }, {})
        return errors
    }
}

export const ProfilePasswordStrategy = () => {

    //translate:
    const { __ } = useTranslate()

    //theme:
    const { token: { fontSize, marginXS, margin, colorBgContainer } } = theme.useToken()
    
    //me:
    const { isLoading: isMeLoading } = useMe()

    //state:
    const [ password, setPassword ] = useState('')

    //mutation:
    const { mutate: savePassword, isLoading: isPasswordLoading, data: response } = useSetPassword(password)

    //effect:
    useEffect(() => { response && queryClient.resetQueries(['me']) }, [response])

    //save:
    const save = (password: string) => {
        setPassword(password)
        savePassword()
    }

    return (
        <Form
            initialValues={{ password: null, passwordRepeat: null }}
            validate={handleValidate(UserPasswordStrategyFormSchema(__))}
            onSubmit={(values: { password: string, passwordRepeat: string }) =>
                UserPasswordStrategyFormSchema(__).validate(values).then(
                    data => save(data.password)
                )
            }
            render={({ handleSubmit }) => (

                <VerticalContainer style={{ gap: margin }}>

                    <VerticalContainer style={{ gap: marginXS }}>
                
                        {/* password */}
                        <Field
                            name="password"
                            title={__`password`}
                            placeholder={__`enter_new_password`}
                            component={FormPassword}
                            size={'large'}
                            style={{ fontSize }}
                        />

                        {/* password repeat */}
                        <Field
                            name="passwordRepeat"
                            title={__`confirm_password`}
                            placeholder={__`confirm_new_password`}
                            component={FormPassword}
                            size={'large'}
                            style={{ fontSize }}
                        />

                    </VerticalContainer>

                    {/* button */}
                    <Button
                        type='primary'
                        size='large'
                        loading={isPasswordLoading || isMeLoading}
                        style={{ fontSize, fontFamily: 'inter-medium', color: colorBgContainer }}
                        onClick={() => {!isPasswordLoading && !isMeLoading && handleSubmit()}}
                    >
                        {__`save_password`}
                    </Button>

                </VerticalContainer>

            )}
        />
    )
    
}
