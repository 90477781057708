import { useEffect, useState } from 'react'
import { useConfig, useCreateUser, useUpdateUser, useUser } from 'api'
import { CreateUserFormSchema, UpdateUserFormSchema, User } from 'types'
import { CloudUploadOutlined } from '@ant-design/icons'
import { Loading, PageContentWrapper, PageError, VerticalContainer } from 'components/styled'
import { TranslateFunction, getShopId, getUserName, queryClient, useTranslate, useWindowDimensions, validateFormValues } from 'utils'
import { Form } from 'react-final-form'
import { Alert, Button, Typography, message, notification, theme } from 'antd'
import { PageHeader } from 'components/page-header/page-header'
import { UserPermissionList } from 'components/user/user-permission-list/user-permission-list'
import { ProfileInfo } from 'components/profile/profile-info/profile-info'
import { useNavigate, useParams } from 'react-router-dom'
import { UserRemove } from 'components/user/user-remove/user-remove'
const { Text } = Typography

export const UserForm = () => {
    
    const { data: config } = useConfig()
    const navigate = useNavigate()
    const params = useParams()

    //redirect if user does not have access:
    useEffect(() => { config && !config.canSetPermissions && navigate('/') }, [config])

    return params.id ? <EditUser id={params.id}/> : <UserFormController user={null}/>

}

type EditUserParams = {
    id: string
}

const EditUser = ({ id }: EditUserParams) => {

    //navigate:
    const navigate = useNavigate()
    
    //user:
    const { data: user, isLoading } = useUser(id, () => navigate('/user'))

    //redirect if not found:
    useEffect(() => {
        if(user === null || user === false){
            navigate('/user')
        }
    }, [isLoading])

    return isLoading ? <Loading/> : !user ? <PageError/> : <UserFormController user={user}/>
}

type ControllerProps = {
    user: User | null
}

export const UserFormController = ({ user }: ControllerProps) => {

    const isCreate = !user

    //hooks:
    const { token: { padding, margin, marginLG, fontSizeLG, colorPrimary } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const navigate = useNavigate()

    //state:
    const [ initialValues, setInitialValues ] = useState(user ?? defaultValues)
    const [ savingValues, setSavingValues ] = useState(user ?? defaultValues)

    //mutation:
    const { mutate: createUser, isLoading: isCreateLoading, data: createResponse } = useCreateUser(savingValues)
    const { mutate: updateUser, isLoading: isUpdateLoading, data: updateResponse } = useUpdateUser(savingValues)

    //openNotification:
    const openNotification = (email: string) => {
        const key = `notification${Date.now()}`
        notification.open({
            key,
            message: <Text style={{ fontSize: fontSizeLG, fontFamily: 'inter-medium' }}>{__`new_user_has_been_created`}</Text>,
            description: __(
                'a_validation_email_will_be_sent_to_the_email_by_clicking_on_the_link_in_that_email_the_user_can_login_into_the_admin',
                { email }
            ),
            btn: (
                <Button
                    type='primary'
                    style={{ backgroundColor: colorPrimary }}
                    onClick={() => notification.destroy(key)}
                >
                    {__`got_it`}
                </Button>
            ),
            placement: 'bottomLeft',
            duration: null,
            closeIcon: null
        })
    }

    //create:
    const create = (values: User) => {
        setSavingValues(values)
        createUser()
    }
    const update = (values: User) => {
        setSavingValues(values)
        updateUser()
    }

    //after create:
    useEffect(() => {
        if(createResponse){
            if(createResponse === 'currentUser'){
                message.error(__`you_cannot_add_your_email`)
            }else if(createResponse === 'existingUser'){
                message.error(__`the_email_already_exists_in_the_shop`)
            }else{
                queryClient.invalidateQueries(['users', getShopId()])
                openNotification(savingValues.email)
                navigate('/user')
            }
        }
    }, [isCreateLoading])

    //after update:
    useEffect(() => {
        if(updateResponse){
            setInitialValues({...savingValues})
            queryClient.invalidateQueries(['user', savingValues.id])
            queryClient.invalidateQueries(['users', getShopId()])
            message.success(__`user_updated`)
        }
    }, [isUpdateLoading])

    return (
        <PageContentWrapper>

            <Form
                subscription={{ dirty: true }}
                initialValues={initialValues}
                onSubmit={(values: User) =>
                    UserFormSchema(isCreate, __).validate(values).then(
                        data => isCreate ? create(data as User) : update(data as User)
                    )
                }
                validate={validateFormValues(UserFormSchema(isCreate, __))}
                render={({ form, dirty, handleSubmit }) => (

                    <VerticalContainer style={{ gap: margin }}>

                        {/* header */}
                        <PageHeader
                            title={isCreate ? __`create_user` : getUserName(user)}
                            description={isCreate ? __`create_new_user_and_assign_permissions` : __`manage_user_permissions`}
                            primaryButton={{
                                name: isCreate ? __`create` : __`save`,
                                icon: <CloudUploadOutlined/>,
                                mode: (isCreateLoading || isUpdateLoading) ? 'loading' : dirty ? 'normal': 'disabled',
                                onClick: handleSubmit
                            }}
                            secondaryButton={dirty ? {
                                name: __`discard`,
                                mode: (isCreateLoading || isUpdateLoading) ? 'disabled' : 'normal',
                                onClick: () => form.reset()
                            } : undefined}
                            backButton={{
                                name: __`back`,
                                onClick: () => navigate('/user')
                            }}
                            isFormDirty={dirty}
                        />

                        <VerticalContainer style={{ padding: SM ? `0 ${padding}px` : 0, gap: marginLG }}>

                            {/* status */}
                            {!isCreate && user && !user.permissionsAccepted &&
                                <div style={{ padding: `0 ${SM ? 0 : padding}px`}}>
                                    <Alert
                                        type='info'
                                        message={__`waiting_for_the_user_to_accept`}
                                        description={__`a_link_has_been_sent_to_the_users_email_but_the_user_has_not_clicked_on_it_yet`}
                                        showIcon
                                    />
                                </div>
                            }

                            {/* info */}
                            <ProfileInfo disableEmail={!isCreate} hideRest={true}/>
                            
                            {/* permission list */}
                            <UserPermissionList/>

                            {/* remove */}
                            {!isCreate && <UserRemove/>}

                        </VerticalContainer>

                    </VerticalContainer>
                )}
            />

        </PageContentWrapper>
    )
    
}

//default values:
const defaultValues: User = {
    id: '',
    email: '',
    firstName: null,
    lastName: null,
    image: {
        path: null,
        id: null
    },
    permissions: [],
    permissionsAccepted: true
}

//UserFormSchema:
const UserFormSchema = (isCreate: boolean, __: TranslateFunction) =>
    isCreate ? CreateUserFormSchema(__) : UpdateUserFormSchema()
