import { useState } from 'react'
import { HorizontalContainer, VerticalContainer } from 'components/styled'
import { NullableField, generatePhraseFromKey, useTranslate, validateFormValues } from 'utils'
import { Field, Form } from 'react-final-form'
import { Button, theme } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { AppExtension, AppExtensionFormSchema, RegisteredApp } from 'types'
import { FormInput, FormSelect } from 'components/form-inputs'
import { AppTarget } from '@sesamiapp/app-message'

type Props = {
    onAdd: (extension: AppExtension) => void,
    onCancel: () => void
}

export const AppExtensionForm = ({ onAdd, onCancel }: Props) => {

    //hooks:
    const { token: { fontSize, marginSM, margin, marginMD }} = theme.useToken()
    const { __ } = useTranslate()

    //state:
    const [ initialValues ] = useState(defaultValues)

    return (
        <Form
            subscription={{ dirty: true }}
            initialValues={initialValues}
            onSubmit={(values: RegisteredApp) =>
                AppExtensionFormSchema(__).validate(values).then(
                    data => onAdd(data as AppExtension)
                )
            }
            validate={validateFormValues(AppExtensionFormSchema(__))}
            render={({ handleSubmit }) => (

                <VerticalContainer style={{ marginTop: margin, gap: marginMD }}>

                    <VerticalContainer style={{ gap: marginSM }}>
                        
                        {/* name */}
                        <Field
                            name='name'
                            title={__`name`}
                            placeholder={__`my_extension`}
                            description={__`the_name_will_appear_just_to_you`}
                            component={FormInput}
                        />

                        {/* target */}
                        <Field
                            name='target'
                            title={__`target`}
                            type='select'
                            options={Object.keys(AppTarget).filter(
                                key => key !== AppTarget.ADMIN_APP_LOADER
                            ).map(
                                key => ({ value: key, label: generatePhraseFromKey(key) })
                            )}
                            component={FormSelect}
                            description={__`the_target_for_loading_the_extension`}
                        />
                        
                        {/* url */}
                        <NullableField
                            name='url'
                            title={__`url`}
                            placeholder="https://myapp.com/extension"
                            description={__`the_url_does_not_need_to_be_unique_the_target_will_pass_to_it_at_the_loading_time`}
                            component={FormInput}
                        />

                    </VerticalContainer>

                    <HorizontalContainer style={{ justifyContent: 'flex-end' }}>

                        <Button
                            type='text'
                            size='large'
                            onClick={onCancel}
                        >
                            {__`cancel`}
                        </Button>

                        <Button
                            type='primary'
                            size='large'
                            icon={<PlusOutlined style={{ fontSize }}/>}
                            onClick={handleSubmit}
                        >
                            {__`add`}
                        </Button>

                    </HorizontalContainer>

                </VerticalContainer>

            )}
        />
    )
    
}

const defaultValues: AppExtension = {
    id: '',
    name: '',
    target: AppTarget.ADMIN_APPOINTMENT_DETAILS,
    url: ''
}
