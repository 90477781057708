import { Config } from 'types'
import { getShopId, queryClient, useApiQuery, useAuthentication } from 'utils'

export const useConfig = () => {

    //hooks:
    const { isLoggedIn, logout } = useAuthentication()
    const shopId = getShopId()

    //values:
    const getBasicConfig = !isLoggedIn || !shopId
    const cacheKey = ['config', getBasicConfig ? null : shopId]

    return useApiQuery<Config>({
        query: getBasicConfig ? `
            query getConfig{
                getConfig{
                    locale
                }
            }
        ` : `
            query getConfig(
                $id: String
            ){
                getConfig(
                    id: $id
                ){

                    locale
                    features
                    maxApp
                    canSetPermissions

                    plan{
                        id
                        planId
                        title
                        name
                        description
                        price
                        maxServices
                        maxTeamMembers
                        maxGroupAppointmentsSlots
                        maxFlows
                        listed
                    }

                }
            }
        `,
        variables: getBasicConfig ? undefined : {
            id: shopId
        },
        cacheKey,
        noAuth: getBasicConfig,
        onSuccess: json => {
            
            let config: Config = json.data.getConfig

            if(!getBasicConfig){
                
                //convert maxTeamMembers to maxResources //!tmp
                config = {
                    ...config,
                    plan: (
                        config.plan === null ? null : (
                            json.data.getConfig.plan.maxTeamMembers !== undefined ? (
                                {
                                    ...config.plan,
                                    maxResources: json.data.getConfig.plan.maxTeamMembers
                                }
                            ) : (
                                config.plan
                            )
                        )
                    )
                }

                // add maxLocations: //!tmp
                config = {
                    ...config,
                    plan: (
                        config.plan === null ? null : (
                            json.data.getConfig.plan.maxLocations === undefined ? (
                                {
                                    ...config.plan,
                                    maxLocations: 5
                                }
                            ) : (
                                config.plan
                            )
                        )
                    )
                }

            }

            return config

        },
        onError: () => {
            queryClient.removeQueries(cacheKey)
            if(isLoggedIn){
                logout()
            }
        }
    })
}
