import { Button, Checkbox, theme } from 'antd'
import { FormSection } from 'components/form-section/form-section'
import { useTranslate, useWindowDimensions } from 'utils'
import { ScreenSize, Location, ServiceLocation } from 'types'
import { HorizontalContainer, VerticalContainer } from 'components/styled'
import { useState } from 'react'
import { useField } from 'react-final-form'
import { useLocations } from 'api'
import { VerticalPaginatedList } from 'components/vertical-paginated-list/vertical-paginated-list'
import { Avatar } from 'components/avatar/avatar'

type AddLocationProps = {
    onAdd: (location: ServiceLocation[]) => void
    onClose: () => void
}

export const ServiceAddLocation = ({ onAdd, onClose }: AddLocationProps) => {

    //hooks:
    const { token: { padding, paddingMD, marginXS }} = theme.useToken()
    const { __ } = useTranslate()
    const { LG, SM, height } = useWindowDimensions()

    //fields:
    const { input: { value: lcs } } = useField('locations')
    const selectedLocations: ServiceLocation[] = lcs

    //state:
    const [ selectedList, setSelectedList ] = useState<ServiceLocation[]>([])
    const [ search, setSearch ] = useState<string | null>(null)

    //api:
    const {
        data: allLocations,
        pageNum,
        totalPages,
        isLoading,
        isPreviousData,
        isNextDisabled,
        isPreviousDisabled,
        fetchNext,
        fetchPrevious
    } = useLocations(search, 5)

    //events:
    const add = (location: Location) => setSelectedList([...selectedList, {
        id: location.id,
        name: location.name,
        description: location.description,
        image: location.image,
        timezone: location.timezone,
        resources: []
    }])
    const remove = (location: Location) => setSelectedList(selectedList.filter(l => l.id !== location.id))

    return (
        <FormSection
            title={__`add_location`}
            searchInput={{
                placeholder: __`search_locations`,
                onSearch: setSearch
            }}
            style={{
                border: 'none',
                boxShadow: 'none',
                padding: SM ? `${paddingMD}px 0` : padding
            }}
        >

            <VerticalContainer style={{ gap: marginXS }}>

                <div style={{ height: LG && height >= ScreenSize.MD ? 330 : undefined }}>

                    <VerticalPaginatedList
                        isFirstLoading={isLoading}
                        isForbidden={allLocations === null}
                        isError={allLocations === false}
                        notFound={(allLocations && allLocations.length === 0) ?? false}
                        totalPages={totalPages}
                        pageNum={pageNum}
                        isPreviousData={isPreviousData}
                        isNextDisabled={isNextDisabled}
                        isPreviousDisabled={isPreviousDisabled}
                        fetchNext={fetchNext}
                        fetchPrevious={fetchPrevious}
                    >

                        <div style={{ height: LG && height >= ScreenSize.MD ? 280 : undefined, overflowY: 'auto' }}>

                            {allLocations && allLocations.map(location => {
                                const isSelected = selectedList.some(l => l.id === location.id) || selectedLocations.some(l => l.id === location.id)
                                const isDisabled = selectedLocations.some(l => l.id === location.id)
                                return (
                                    <ServiceLocationSelectItem
                                        key={location.id}
                                        name={location.name}
                                        description={location.description}
                                        image={location.image.path}
                                        isSelected={isSelected}
                                        isDisabled={isDisabled}
                                        onSelect={() => isSelected ? remove(location) : add(location)}
                                    />
                                )
                            })}
                        
                        </div>

                    </VerticalPaginatedList>

                </div>
                
                <HorizontalContainer style={{
                    justifyContent: 'flex-end',
                    gap: marginXS
                }}>

                    <Button onClick={onClose}>
                        {__`cancel`}
                    </Button>

                    <Button type='primary' onClick={() => onAdd(selectedList)}>
                        {__`add`}
                    </Button>

                </HorizontalContainer>

            </VerticalContainer>

        </FormSection>
    )

}

type ItemProps = {
    name: string
    description: string | null
    image: string | null
    isSelected: boolean
    isDisabled: boolean
    onSelect: () => void
}

const ServiceLocationSelectItem = ({
    name,
    description,
    image,
    isSelected,
    isDisabled,
    onSelect
}: ItemProps) => {

    //hooks:
    const { token: { paddingXXS, marginXS } } = theme.useToken()
    const { __ } = useTranslate()
    const { SM } = useWindowDimensions()
    
    return (
        <Button
            type='text'
            disabled={isDisabled}
            title={isSelected ? __`location_is_already_added` : undefined}
            style={{
                width: '100%',
                height: 'auto',
                padding: `0 ${paddingXXS}px`,
                display: 'flex',
                alignItems: 'center',
                gap: marginXS,
                borderRadius: SM ? undefined : 0,
                opacity: isDisabled ? .7 : 1
            }}
            onClick={onSelect}>

            <Checkbox
                checked={isSelected}
                disabled={isDisabled}
            />
            
            <Avatar
                name={name}
                description={description}
                image={image}
                shape='square'
                size='small'
            />

        </Button>
    )
    
}
