import { Select, theme, Typography } from 'antd'
import { useTranslate } from 'utils'
import { useEffect, useRef, useState } from 'react'
import { Loading, NotFound, PageError } from 'components/styled'
import { useResourceTypes } from '../../../api'
import { DEBOUNCING_TIMEOUT } from '../../../app.constants'
import { ResourceType } from 'types'
const { Text } = Typography

type Props = {
    placeholder?: string
    extraOptions?: { value: string, label: string }[]
    value: string | null,
    selectFirstOnLoad?: boolean
    clearSearchAfterSelect?: boolean
    style?: object
    onResourceTypeSelect: (type: ResourceType) => void
}

export const ResourceTypeSelect = ({
    placeholder,
    extraOptions,
    value,
    selectFirstOnLoad,
    clearSearchAfterSelect,
    style,
    onResourceTypeSelect
}: Props) => {

    //ref:
    const select = useRef<any>(null)

    //debounce timeout:
    const lastKeyPressTimeoutId = useRef(0 as number | NodeJS.Timeout)

    //hooks:
    const { token: { colorTextPlaceholder }} = theme.useToken()
    const { __ } = useTranslate()
    
    //state:
    const [ isFocused, setIsFocused ] = useState(false)
    const [ isTyping, setIsTyping ] = useState(false)
    const [ search, setSearch ] = useState<string | null>(null)

    //api:
    const {
        data: types,
        isLoading,
        isFetching,
        isError
    } = useResourceTypes(search, 5)

    //after loading types, select the first one (when create)
    useEffect(() => {
        if(selectFirstOnLoad && types && types.length && !value){
            onResourceTypeSelect(types[0])
        }
    }, [types])

    const onSearch = (searchPhrase: string) => {
        setIsTyping(true)
        clearTimeout(lastKeyPressTimeoutId.current)
        if(searchPhrase.length < 1){
            setSearch(null)
            setIsTyping(false)
        }else{
            lastKeyPressTimeoutId.current = setTimeout(() => {
                setSearch(searchPhrase)
                setIsTyping(false)
            }, DEBOUNCING_TIMEOUT)
        }
    }

    return (
        <Select
            ref={select}
            placeholder={
                placeholder ? (
                    <Text style={{ color: isFocused ? colorTextPlaceholder : undefined }}>{placeholder}</Text>
                ) : undefined
            }
            options={(extraOptions ?? []).concat(types ? types.map(r => ({ value: r.id, label: r.name })) : [])}
            value={value}
            autoFocus
            showSearch
            allowClear={search ? true : false}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onClear={() => setSearch(null)}
            onSearch={onSearch}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            notFoundContent={
                (isLoading || isFetching || isTyping) ? <Loading/> :
                isError ? <PageError message={__`error`}/> :
                <NotFound/>
            }
            onChange={(value, option: any) => {
                onResourceTypeSelect(option ? {
                    id: option.value,
                    name: option.label
                } : extraOptions ? {
                    id: extraOptions[0].value,
                    name: extraOptions[0].label
                } : {
                    id: '',
                    name: ''
                })
                clearSearchAfterSelect && setSearch(null)
                select.current.blur()
            }}
            style={style}
        />
    )
}
