import { canRegisterApp } from '../registered-apps-list/registered-apps-list'
import { useEffect, useState } from 'react'
import { CloudUploadOutlined } from '@ant-design/icons'
import { Loading, PageContentWrapper, PageError, RWContainer, RWElement, VerticalContainer } from 'components/styled'
import { queryClient, TranslateFunction, useTranslate, useWindowDimensions, validateFormValues } from 'utils'
import { Field, Form } from 'react-final-form'
import { message, theme, Typography } from 'antd'
import { PageHeader } from 'components/page-header/page-header'
import { useNavigate, useParams } from 'react-router-dom'
import { useRegisteredApp, useConfig, useRegisterApp, useUpdateApp, useRegisteredApps } from 'api'
import { AppDistribution, Feature, RegisterAppFormSchema, RegisteredApp, UpdateAppFormSchema } from 'types'
import { FormSection } from 'components/form-section/form-section'
import { AppInfo } from 'components/app/app-info/app-info'
import { AppNetwork } from 'components/app/app-network/app-network'
import { AppClientId } from 'components/app/app-client-id/app-client-id'
import { FormRadio } from 'components/form-inputs'
import { ImageUpload } from 'components/image-upload/image-upload'
import { AppExtensionsList } from 'components/app/app-extensions-list/app-extensions-list'
import { AppRegistrationModal } from 'components/app/app-registration-modal/app-registration-modal'
const { Text } = Typography

export const AppForm = () => {

    //hooks:
    const { data: config } = useConfig()
    const navigate = useNavigate()
    const params = useParams()

    //api:
    const { data: apps, total } = useRegisteredApps(null, 15)

    //redirect if cannot register:
    useEffect(() => {
        if(config && apps){
            if(!config.features.includes(Feature.APPS)){
                navigate('/')
            }else if(!params.id && !canRegisterApp(config, true, total)){ // is register (not edit)
                navigate('/developers')
            }
        }
    }, [config, apps])

    return params.id ? <EditApp id={params.id}/> : <AppFormController app={null}/>

}

type EditAppParams = {
    id: string
}

const EditApp = ({ id }: EditAppParams) => {

    //hooks:
    const navigate = useNavigate()
    
    //app:
    const { data: app, isLoading } = useRegisteredApp(id, () => navigate('/developers'))

    return isLoading ? <Loading/> : !app ? <PageError/> : <AppFormController app={app}/>

}

type ControllerProps = {
    app: RegisteredApp | null
}

export const AppFormController = ({ app }: ControllerProps) => {

    //isRegister:
    const isRegister = !app

    //hooks:
    const { token: { padding, marginXS, margin, marginLG } } = theme.useToken()
    const { SM } = useWindowDimensions()
    const { __ } = useTranslate()
    const navigate = useNavigate()

    //state:
    const [ initialValues ] = useState(getInitialValues(app ?? defaultValues))
    const [ savingValues, setSavingValues ] = useState(app ?? defaultValues)
    const [ clientId, setClientId] = useState<string | null>(null)
    const [ clientSecret, setClientSecret] = useState<string | null>(null)
    const [ isModalOpen, setIsModalOpen] = useState(false)

    //mutations:
    const { mutate: registerApp, isLoading: isRegisterLoading, data: registerResponse } = useRegisterApp(savingValues)
    const { mutate: updateApp, isLoading: isUpdateLoading, data: updateResponse } = useUpdateApp(savingValues)

    //register:
    const register = (values: RegisteredApp) => {
        setSavingValues(values)
        registerApp()
    }

    //update:
    const update = (values: RegisteredApp) => {
        setSavingValues(values)
        updateApp()
    }

    //after register:
    useEffect(() => {
        if(registerResponse){
            if(registerResponse === 'existingName'){
                message.error(__`the_name_is_used_before`)
            }else if(registerResponse === 'exceedMaxApp'){
                message.error(__`you_exceeded_the_max_apps_limit`)
            }else{
                queryClient.invalidateQueries(['registeredApps'])
                setClientId(registerResponse.clientId)
                setClientSecret(registerResponse.clientSecret)
                setIsModalOpen(true)
            }
        }
    }, [isRegisterLoading])

    //after update:
    useEffect(() => {
        if(updateResponse){
            queryClient.invalidateQueries(['registeredApps'])
            message.success(__`app_updated`)
        }
    }, [isUpdateLoading])

    return (
        <PageContentWrapper>

            <Form
                subscription={{ dirty: true }}
                initialValues={initialValues}
                onSubmit={(values: RegisteredApp) => (
                    AppFormSchema(isRegister, __).validate(values).then(
                        data => isRegister ? register(data as RegisteredApp) : update(data as RegisteredApp)
                    )
                )}
                validate={validateFormValues(AppFormSchema(isRegister, __))}
                render={({ form, dirty, handleSubmit }) => (

                    <VerticalContainer style={{ gap: margin }}>

                        {/* header */}
                        <PageHeader
                            title={isRegister ? __`register_app` : app.name}
                            description={isRegister ? __`register_a_new_app_publish_it_or_use_it_as_private` : __`manage_your_app`}
                            primaryButton={{
                                name: isRegister ? __`register` : __`save`,
                                icon: <CloudUploadOutlined/>,
                                mode: (isRegisterLoading || isUpdateLoading) ? 'loading' : dirty ? 'normal': 'disabled',
                                onClick: handleSubmit
                            }}
                            secondaryButton={dirty ? {
                                name: __`discard`,
                                mode: (isRegisterLoading || isUpdateLoading) ? 'disabled' : 'normal',
                                onClick: () => form.reset()
                            } : undefined}
                            backButton={{
                                name: __`back`,
                                onClick: () => navigate('/developers')
                            }}
                            isFormDirty={dirty}
                        />

                        <VerticalContainer style={{ padding: SM ? `0 ${padding}px` : 0, gap: marginLG }}>

                            <FormSection style={{ minHeight: 0, paddingBottom: SM ? marginLG : 0 }}>

                                <RWContainer style={{ alignItems: 'normal', gap: marginLG }}>

                                    {/* info */}
                                    <RWElement breakPoint={300}>
                                        <AppInfo isRegister={isRegister}/>
                                    </RWElement>

                                    {/* icon */}
                                    <RWElement breakPoint={300}>

                                        <VerticalContainer style={{ height: '100%', gap: marginXS }}>

                                            <Text style={{ fontFamily: 'inter-medium' }}>
                                                {__`icon`}
                                            </Text>

                                            <ImageUpload
                                                type='app'
                                                nameField='name'
                                                imageField='icon'
                                            />

                                        </VerticalContainer>

                                    </RWElement>

                                </RWContainer>

                            </FormSection>

                            {/* network */}
                            <AppNetwork/>

                            {/* extensions */}
                            <AppExtensionsList/>

                            {/* distribution */}
                            <FormSection title={__`distribution`}>
                                
                                {/* distribution */}
                                <Field
                                    name='distribution'
                                    type='select'
                                    options={[
                                        { value: AppDistribution.PUBLIC , text: __`public` , description: __`will_be_accessible_for_all_the_shops`    },
                                        { value: AppDistribution.PRIVATE, text: __`private`, description: __`can_be_installed_via_an_invitation_link` },
                                    ]}
                                    component={FormRadio}
                                    maxWidth={750}
                                    breakPoint={150}
                                />

                            </FormSection>

                            {/* client id */}
                            {!isRegister && <AppClientId/>}

                        </VerticalContainer>

                    </VerticalContainer>
                )}
            />

            {/* modal */}
            <AppRegistrationModal
                clientId={clientId}
                clientSecret={clientSecret}
                isModalOpen={isModalOpen}
            />

        </PageContentWrapper>
    )
    
}

//default values:
const defaultValues: RegisteredApp = {
    id: '',
    name: '',
    title: null,
    icon: {
        path: null,
        id: null
    },
    description: null,
    domain: '',
    redirectURIs: [],
    extensions: [],
    distribution: AppDistribution.PUBLIC,
    clientId: null
}

const getInitialValues = (app: RegisteredApp) => {
    return { ...app, inheritedPermissions: [] }
}

const AppFormSchema = (isRegister: boolean, __: TranslateFunction) => (
    isRegister ? RegisterAppFormSchema(__) : UpdateAppFormSchema(__)
)
