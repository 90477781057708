import { Drawer, Modal, theme } from 'antd'
import { FormSection } from 'components/form-section/form-section'
import { useTranslate, useWindowDimensions } from 'utils'
import { Feature, ScreenSize, ServiceLocation, ServiceLocationResources } from 'types'
import { NotFound, VerticalContainer } from 'components/styled'
import { PlusOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useField } from 'react-final-form'
import { useConfig } from 'api'
import { ServiceResourceConfigurator } from '../service-resource-configurator/service-resource-configurator'
import { ServiceLocationItem } from '../service-location-item/service-location-item'
import { ServiceAddLocation } from '../service-add-location/service-add-location'

export const ServiceLocations = () => {

    //hooks:
    const { token: { padding, marginXXS, marginXS, fontSize } } = theme.useToken()
    const { data: config } = useConfig()
    const { __ } = useTranslate()
    const { MD, LG, width, height } = useWindowDimensions()

    //fields:
    const { input: { value: lcs , onChange: updateLocations } } = useField('locations')
    const locations: ServiceLocation[] = lcs

    //state:
    const [ selectedLocation, setSelectedLocation ] = useState<ServiceLocation | null>(null)
    const [ isAddLocationOpen, setIsAddLocationOpen ] = useState(false)
    const [ isConfiguratorOpen, setIsConfiguratorOpen ] = useState(false)

    const isConfiguratorModal = LG && height >= ScreenSize.MD

    const getConfiguratorContent = () => (
        <div style={{
            height: isConfiguratorModal ? 700 : '100%',
            marginTop: (isConfiguratorModal && height < ScreenSize.LG) ? -80 : undefined
        }}>
            {selectedLocation && (
                <ServiceResourceConfigurator
                    selectedLocation={selectedLocation}
                    onSave={handleUpdateLocation}
                    onCancel={() => setIsConfiguratorOpen(false)}
                />
            )}
        </div>
    )

    const handleUpdateLocation = (resources: ServiceLocationResources[]) => {
        const locationIndex = locations.findIndex(l => l.id === selectedLocation?.id)
        const lcs = [...locations]
        lcs[locationIndex] = {
            ...lcs[locationIndex],
            resources: resources
        }
        updateLocations([...lcs])
        setIsConfiguratorOpen(false)
    }

    return config ? (

        <FormSection
            title={__`locations_and_resources`}
            topButton={config.features.includes(Feature.MULTI_LOCATION) ? {
                label: __`add_location`,
                icon: <PlusOutlined/>,
                onClick: () => setIsAddLocationOpen(true)
            } : undefined}
        >
            
            {/* list */}
            {locations.length > 0 ? (
                <VerticalContainer style={{ gap: marginXS }}>
                    {locations.map(location => (
                        <ServiceLocationItem
                            key={location.id}
                            location={location}
                            onSelect={() => {
                                setSelectedLocation(location)
                                setIsConfiguratorOpen(true)
                            }}
                            onRemove={() => {
                                const newLocations = locations.filter(l => l.id !== location.id)
                                updateLocations([...newLocations])
                            }}
                        />
                    ))}
                </VerticalContainer>
            ) : (
                <NotFound
                    message={__`this_service_is_not_provided_in_any_locations`}
                    style={{ fontSize, marginBottom: marginXXS }}
                />
            )}

            {/* add location */}
            <Modal
                open={isAddLocationOpen}
                closeIcon={null}
                footer={null}
                destroyOnClose
                style={{
                    minWidth: MD ? ScreenSize.MD : width,
                    height: 500,
                    padding
                }}
                styles={{ content: { padding: 0 } }}
                onCancel={() => setIsAddLocationOpen(false)}
            >

                <ServiceAddLocation
                    onAdd={addedLocations => {
                        updateLocations([...locations.concat(addedLocations)])
                        setIsAddLocationOpen(false)
                    }}
                    onClose={() => setIsAddLocationOpen(false)}
                />

            </Modal>
            
            {/* configurator */}
            {isConfiguratorModal ? (
                
                <Modal
                    open={isConfiguratorOpen}
                    closeIcon={null}
                    footer={null}
                    destroyOnClose
                    style={{
                        minWidth: LG ? ScreenSize.LG : width,
                        padding
                    }}
                    styles={{ content: { padding: 0 } }}
                    onCancel={() => setIsConfiguratorOpen(false)}
                >

                    {getConfiguratorContent()}

                </Modal>

            ) : (

                <Drawer
                    closable={false}
                    width={width}
                    destroyOnClose
                    open={isConfiguratorOpen}
                    styles={{ body: { padding: 0 }}}
                    onClose={() => setIsConfiguratorOpen(false)}
                >

                    {getConfiguratorContent()}
                    
                </Drawer>
                
            )}

        </FormSection>
    ) : <></>

}
