import { Location } from 'types'
import {
    getLocationId,
    getRefreshTokenUserId,
    getShopId, isNil, refreshToken, updateLocationId, updateShopId, useMutationHandler,
    usePaginationHandler,
    useQueryHandler
} from 'utils'

const locationQuery = `

    id
    name
    phone
    email
    description
    image {
        id
        path
    }

    timezone
    
    businessAddress {
        country
        address
        suite
        city
        state
        zipCode
        latitude
        longitude
    }
    
    isDefault
    status
    
`

//useLocations:
export const useLocations = (search: string | null, limit: number, enabled?: boolean) => {
    const shopId = getShopId()
    return usePaginationHandler<Location[]>({
        query: `
            query getLocations (
                $shop: String!,
                $searchTerm: String,
                $limit: Int!,
                $after: String,
                $before: String
            ) {
                getLocations(
                    shop: $shop,
                    searchTerm: $searchTerm,
                    limit: $limit,
                    after: $after,
                    before: $before
                ) {
                    data {
                        ${locationQuery}
                    }
                    totalItems
                    remainingItems
                    nextCursor
                    previousCursor
                }
            }
        `,
        variables: {
            shop: shopId,
            searchTerm: search,
            limit,
            after: null,
            before: null
        },
        cacheKey: ['locations', shopId, search],
        limit,
        enabled,
        getResponse: json => json.data.getLocations,
        onSuccess: response => response.data
    })

}

//useLocation:
export const useLocation = (id: string, onNotFound?: (json: any) => void) => {
    const shopId = getShopId()
    return useQueryHandler<Location>({
        query: `
            query getLocation(
                $shop: String!,
                $locationId: String!
            ){
                getLocation(
                    shop: $shop,
                    locationId: $locationId
                ){
                    ${locationQuery}
                }
            }
        `,
        variables: {
            shop: shopId,
            locationId: id
        },
        cacheKey: ['location', id, shopId],
        onSuccess: json => json.data.getLocation,
        onNotFound
    })
}

export const useCurrentLocation = () => {

    const shopId = getShopId()
    const locationId = getLocationId()
    const hasLocation = locationId !== ''

    const removeLocation = () => {
        updateLocationId(null)
        location.reload()
    }
    
    //handler:
    return useQueryHandler<Location>({
        query: hasLocation ? `
            query getLocation(
                $shop: String!,
                $locationId: String!
            ){
                getLocation(
                    shop: $shop,
                    locationId: $locationId
                ){
                    ${locationQuery}
                }
            }
        ` : `
            query getLocations(
                $shop: String!,
                $limit: Int!
            ){
                getLocations(
                    shop: $shop,
                    limit: $limit
                ){
                    data{
                        ${locationQuery}
                    }
                }
            }
        `,
        variables: hasLocation ? {
            shop: shopId,
            locationId
        } : {
            shop: shopId,
            limit: 1
        },
        cacheKey: ['currentLocation', shopId, locationId, getRefreshTokenUserId(refreshToken())],
        enabled: !isNil(refreshToken()),
        onSuccess: json => {
            
            if(!hasLocation && json.data.getLocations.data.length === 0){ //!tmp: the shop is not migrated
                updateShopId('5f221d7874241b002709b8db') //!tmp: switch to sesami-dev
                location.reload()
            }

            const _location: Location = hasLocation ? json.data.getLocation : json.data.getLocations.data[0]
            if(!hasLocation){
                updateLocationId(_location.id)
            }
            return _location
        },
        onNotFound: removeLocation,
        onForbidden: removeLocation,
        onUnknownError: removeLocation
    })

}

//create:
export const useCreateLocation = (location: Location) => {
    const shopId = getShopId()
    return useMutationHandler<string>({
        query: `
            mutation createLocation(
                $shop: String!,
                $request: CreateLocationRequest!,
            ){
                createLocation(
                    shop: $shop,
                    request: $request
                ){
                    id
                }
            }
        `,
        variables: {
            shop: shopId,
            request: {

                name: location.name,
                phone: location.phone,
                email: location.email,
                description: location.description,
                image: location.image.id,

                timezone: location.timezone,

                businessAddress: location.businessAddress,

                isDefault: location.isDefault,
                status: location.status

            }

        },
        onSuccess: json => json.data.createLocation.id
    })

}

//update:
export const useUpdateLocation = (location: Location) => {
    const shopId = getShopId()
    return useMutationHandler<boolean>({
        query: `
            mutation updateLocation(
                $shop: String!,
                $locationId: String!,
                $request: UpdateLocationRequest!,
            ){
                updateLocation(
                    shop: $shop,
                    locationId: $locationId,
                    request: $request
                ){
                    id
                }
            }
        `,
        variables: {
            shop: shopId,
            locationId: location.id,
            request: {

                name: location.name,
                phone: location.phone,
                email: location.email,
                description: location.description,
                image: location.image.id,

                timezone: location.timezone,

                businessAddress: location.businessAddress,

                isDefault: location.isDefault,
                status: location.status
                
            }

        },
        onSuccess: json => json.data.updateLocation.id === location.id
    })
}

//remove:
export const useRemoveLocation = (id: string) => {
    const shopId = getShopId()
    return useMutationHandler<boolean | 'assignedToService'>({
        query: `
            mutation deleteLocation(
                $shop: String!,
                $locationId: String!
            ){
                deleteLocation(
                    shop: $shop,
                    locationId: $locationId
                ){
                    id
                }
            }
        `,
        variables: {
            shop: shopId,
            locationId: id
        },
        onSuccess: json => json.data.deleteLocation.id === id,
        onError: json => {
            switch(json.errors[0].extensions.errors[0].constraints[0].name){
                case 'location_has_assigned_to_a_service':
                    return 'assignedToService'
                default:
                    return false
            }
        }
    })
}
