import { DatePickerWrapper, MainWrapper, ResourcesWrapper, MenuWrapper, TextWrapper } from './calendar-page-navigation.style'
import { Button, DatePicker, Select, Typography, theme } from 'antd'
import { LoadingOutlined, ReloadOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useTranslate, addDays, getDateRange, min, queryClient } from 'utils'
import { useConfig, useResources } from 'api'
import { HorizontalContainer, Loading, NotFound, PageError } from 'components/styled'
import { Shop, Resource } from 'types'
import { useEffect, useRef, useState } from 'react'
import { CALENDAR_TEAM_MEMBER_LCL_STR_KEY, DEBOUNCING_TIMEOUT } from 'app.constants'
import { Avatar } from 'components/avatar/avatar'
import dayjs from 'dayjs'
const { Text } = Typography

type Props = {
    shop: Shop,
    startDate: Date
    numberOfDays: number
    selectedResources: Resource[]
    areSlotsLoading: boolean
    onStartDateChange: (date: Date) => void
    onResourcesChange: (resources: Resource[]) => void
}

export const CalendarPageNavigation = ({
    shop,
    startDate,
    numberOfDays,
    selectedResources,
    areSlotsLoading,
    onStartDateChange,
    onResourcesChange
}: Props) => {

    //hooks:
    const localStorageIds = getResourcesFromLocalStorage(shop.id)
    const { token: { fontSizeSM, fontSizeLG, marginXXS } } = theme.useToken()
    const { __ } = useTranslate()
    const { data: config, isLoading: isConfigLoading } = useConfig()
    const container = useRef<HTMLDivElement>(null)
    const lastKeyPressTimeoutId = useRef(0 as number | NodeJS.Timeout)

    //state:
    const [ isRefreshSelected, setIsRefreshSelected ] = useState(false)
    const [ search, setSearch ] = useState<string | null>(null)
    const [ isTyping, setIsTyping ] = useState(false)

    //resources:
    const { data: resources, isLoading: isResourcesLoading, isFetching: isResourcesFetching } = useResources(null, null, search, 15)

    //select resources:
    useEffect(() => {
        if(resources){
            if(resources.length){
                if(localStorageIds.length > 0){
                    onResourcesChange(resources.filter(resource => localStorageIds.includes(resource.id)))
                }else if(container.current){
                    const containerWidth = container.current.offsetWidth
                    const columnsNumber = min(Math.floor(containerWidth / 150), resources.length)
                    const selectedTM = []
                    for(let i=0; i < columnsNumber; i++){
                        selectedTM.push(resources[i])
                    }
                    onResourcesChange(selectedTM)
                }
            }else{
                onResourcesChange([])
            }
        }
    }, [isResourcesLoading, shop])

    // disable refresh button loading for other fetches:
    useEffect(() => { !areSlotsLoading && setIsRefreshSelected(false) }, [areSlotsLoading])

    //handleSearch:
    const handleSearch = (search: string) => {
        setIsTyping(true)
        clearTimeout(lastKeyPressTimeoutId.current)
        if(search.length < 1){
            setSearch(null)
            setIsTyping(false)
        }else{
            lastKeyPressTimeoutId.current = setTimeout(() => {
                setSearch(search)
                setIsTyping(false)
            }, DEBOUNCING_TIMEOUT)
        }
    }

    //updateSelectedResources:
    const updateSelectedResources = (shop: Shop, newValue: string[]) => {
        if(resources){
            
            // find the id list:
            const selectedResourceIds = newValue.map(val => val.split('__')[0])
            
            // convert the id list to the object list:
            const selectedTMs: Resource[] = []
            selectedResourceIds.map(selectedId => {
                const selectedResource = resources.find(resource => resource.id === selectedId)
                if(selectedResource){
                    selectedTMs.push(selectedResource)
                }
            })

            // update the selected list:
            onResourcesChange(selectedTMs)

            // update the local storage:
            saveResourcesToLocalStorage(shop.id, selectedResourceIds)

        }
    }
    
    return (
        (isConfigLoading || !config) ? null :
        <MainWrapper ref={container}>
            
            <TextWrapper>

                {/* date */}
                <Text style={{ fontSize: fontSizeLG, fontFamily: 'inter-medium' }}>
                    {getDateRange(startDate, numberOfDays, config, true)}
                </Text>
            
            </TextWrapper>

            <MenuWrapper>

                <DatePickerWrapper>

                    {/* date picker */}
                    <DatePicker
                        inputReadOnly
                        defaultValue={dayjs(startDate)}
                        value={dayjs(startDate)}
                        allowClear={false}
                        style={{ width: '100%', height: 'fit-content' }}
                        onChange={val => onStartDateChange(dayjs(val).toDate())}
                    />

                    <HorizontalContainer>

                        {/* left arrow */}
                        <Button
                            icon={<LeftOutlined style={{ fontSize: fontSizeSM }}/>}
                            title={__`previous_day`}
                            size={'middle'}
                            style={{ fontSize: fontSizeSM, backgroundColor: 'transparent' }}
                            onClick={() => onStartDateChange(addDays(startDate, -1))}
                        />
                        
                        {/* right arrow */}
                        <Button
                            icon={<RightOutlined style={{ fontSize: fontSizeSM }}/>}
                            title={__`next_day`}
                            size={'middle'}
                            style={{ fontSize: fontSizeSM, backgroundColor: 'transparent' }}
                            onClick={() => onStartDateChange(addDays(startDate, 1))}
                        />

                        {/* refresh */}
                        <Button
                            icon={(areSlotsLoading && isRefreshSelected) ? <LoadingOutlined style={{ fontSize: fontSizeSM }}/> : <ReloadOutlined style={{ fontSize: fontSizeSM }}/>}
                            title={__`refresh`}
                            size={'middle'}
                            style={{ fontSize: fontSizeSM, backgroundColor: 'transparent' }}
                            onClick={() => {
                                setIsRefreshSelected(true)
                                queryClient.invalidateQueries(['calendarSlots'])
                            }}
                        />
                        
                    </HorizontalContainer>

                </DatePickerWrapper>

                <ResourcesWrapper>

                    {/* resources */}
                    <Select
                        mode="multiple"
                        options={isResourcesLoading ? [] : getResourcesOptions(resources ? resources : [])}
                        value={(isResourcesLoading || !resources) ? undefined : getResourcesValue(selectedResources, resources)}
                        notFoundContent={
                            (isResourcesLoading || isResourcesFetching || isTyping) ? <Loading/> :
                            !resources ? <PageError message={__`error`}/> :
                            <NotFound message={search ? __`not_found` : __`no_resources`}/>
                        }
                        maxTagCount='responsive'
                        maxTagTextLength={4}
                        placeholder={__`select_resources`}
                        style={{ width: '100%' }}
                        tagRender={(props: any) => (
                            <div style={{ marginRight: marginXXS }}>
                                {getResourceImage(resources ? resources : [], props.value)}
                            </div>
                        )}
                        onSearch={handleSearch}
                        onChange={newValue => updateSelectedResources(shop, newValue)}
                    />

                </ResourcesWrapper>

            </MenuWrapper>

        </MainWrapper>
    )
    
}

//getResourcesOptions:
const getResourcesOptions = (resources: Resource[]) => resources.map(resource => ({
    value: getResourceSelectorValue(resource),
    label: resource.name
}))

//getResourcesValue:
const getResourcesValue = (selectedResources: Resource[] | undefined, resources: Resource[]) => {
    if(selectedResources){
        return selectedResources.map(selectedResource => {
            const resource = resources.find(resource => resource.id === selectedResource.id)
            return resource ? getResourceSelectorValue(resource) : ''
        })
    }else{
        return []
    }
}

//getResourceImage:
const getResourceImage = (resources: Resource[], value: string) => {
    const resource = resources.find(resource => getResourceSelectorValue(resource) === value)
    return (
        <Avatar
            image={resource?.image.path}
            placeholder={resource?.name[0]}
            size='micro'
            style={{ padding: 0 }}
        />
    )
}

//getResourceSelectorValue:
const getResourceSelectorValue = (resource: Resource) => (
    `${resource.id}__${resource.name}__${resource.email}__${resource.mobile}__${resource.description}` // to be able to search locally
)

type Storage = {
    shopId: string,
    resourceIds: string[]
}

//getResourcesFromLocalStorage:
const getResourcesFromLocalStorage = (shopId: string) => {
    const storageList = getAllShopsResourcesFromLocalStorage()
    const storage = storageList.find(st => st.shopId === shopId)
    return storage?.resourceIds ?? []
}

//getResourcesFromLocalStorage:
const getAllShopsResourcesFromLocalStorage = () => {
    let storageList: Storage[] = []
    try{
        const storageListString = localStorage.getItem(CALENDAR_TEAM_MEMBER_LCL_STR_KEY)
        if(storageListString){
            storageList = JSON.parse(storageListString)
        }
    }catch(e){e}
    return storageList
}

//saveResourcesToLocalStorage:
const saveResourcesToLocalStorage = (shopId: string, resourceIds: string[]) => {
    const storageList = getAllShopsResourcesFromLocalStorage()
    let newStorageList = [...storageList]
    const isShopExists = storageList.find(st => st.shopId === shopId)
    if(isShopExists){
        newStorageList = storageList.map(st => {
            if(st.shopId === shopId){
                return { ...st, resourceIds }
            }else{
                return st
            }
        })
    }else{
        newStorageList.push({ shopId, resourceIds })
    }
    try{
        localStorage.setItem(CALENDAR_TEAM_MEMBER_LCL_STR_KEY, JSON.stringify(newStorageList))
    }catch(e){e}
}
